@charset "UTF-8";
/**
 * ----------------------------------------------------------------------------
 * Global sipmle styles
 * ----------------------------------------------------------------------------
*/
.uppercase {
  text-transform: uppercase; }

.swiper-container {
  overflow: hidden; }

/**
 * ----------------------------------------------------------------------------
 * Custom Cart Dot styles
 * ----------------------------------------------------------------------------
*/
.Header__CartDot {
  position: absolute;
  top: -6px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #FED30E !important;
  box-shadow: none;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 13px;
  color: var(--text-color); }

/**
 * ----------------------------------------------------------------------------
 * Custom Header styles
 * ----------------------------------------------------------------------------
*/
.Header .Header__Menu__Icon {
  order: 3;
  padding-left: 8%;
  max-width: fit-content; }

@media screen and (min-width: 1140px) {
  .Header:not(.Header--sidebar) .Header__Wrapper {
    padding: 1.6%; }
  .Header .Header_Desktop_Logo > svg {
    width: 33vw; }
  .Header .Header__MainNav .HorizontalList .HorizontalList__Item {
    font-weight: 500;
    text-transform: uppercase;
    color: var(--text-color); }
    .Header .Header__MainNav .HorizontalList .HorizontalList__Item a > svg {
      margin-left: 10px;
      top: -2px;
      position: relative; }
  .Header .Header__Menu__Icon {
    order: 0;
    padding-left: 0;
    max-width: auto; }

  .Header__MainNav {
    margin-right: 0; } }
@media screen and (min-width: 1440px) {
  .Header:not(.Header--sidebar) .Header__Wrapper {
    padding: 1.6% 10%; } }
/**
 * ----------------------------------------------------------------------------
 * Custom Announcement Bar styles
 * ----------------------------------------------------------------------------
*/
.AnnouncementBar__Wrapper {
  display: flex;
  justify-content: space-around;
  padding: 6px 15px; }
  .AnnouncementBar__Wrapper > p {
    font-weight: 200;
    margin: 0;
    padding: 0; }

/**
 * ----------------------------------------------------------------------------
 * Custom button styles
 * ----------------------------------------------------------------------------
*/
.Button {
  height: 40px;
  width: 100%;
  max-width: 300px;
  color: #F7F7FC;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.75px;
  text-transform: none;
  transform: none !important; }
  @media screen and (min-width: 641px) {
    .Button {
      height: 64px;
      font-size: 16px;
      line-height: 28px; } }
  .Button::before {
    transform: none !important;
    border-radius: 40px; }
  .Button:hover {
    opacity: 0.8;
    transform: none !important; }

.Button--secondary {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  background-color: transparent;
  color: var(--button-background);
  border: 2px solid var(--button-background); }

.Input {
  background: #FCFCFC;
  border: 2px solid var(--button-background);
  box-sizing: border-box;
  border-radius: 28px;
  min-height: 60px;
  padding: 12px 42px; }
  .Input::placeholder {
    margin-left: 40px;
    font-weight: 200;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    color: #828282; }

.Textarea {
  background: #FCFCFC;
  border: 2px solid var(--button-background);
  box-sizing: border-box;
  border-radius: 28px;
  min-height: 60px;
  padding: 28px 42px; }
  .Textarea::placeholder {
    margin-left: 40px;
    font-weight: 200;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.75px;
    color: #828282; }

.Input_label {
  color: var(--button-background);
  margin-left: 30px;
  font-size: 16px; }

/**
 * ----------------------------------------------------------------------------
 * RaoHomeHeader block
 * ----------------------------------------------------------------------------
*/
.RaoHomeHeader {
  position: relative;
  overflow: hidden; }
  .RaoHomeHeader .RaoHomeHeader__FirstContainer {
    height: 100%;
    display: flex; }
    .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content {
      padding-top: 22%;
      padding-left: 4%;
      width: 90%; }
      .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1px; }
      .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper > .Button {
        width: 145px; }
  .RaoHomeHeader .RaoHomeHeader__SecondContainer {
    height: var(--secondContainerHeight); }
    .RaoHomeHeader .RaoHomeHeader__SecondContainer::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1; }
    .RaoHomeHeader .RaoHomeHeader__SecondContainer .RaoHomeHeader__SecondContainer_Wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 53%;
      height: 100%;
      padding-left: 1%; }
  .RaoHomeHeader .RaoTextWithImage__ImageContainer {
    position: absolute;
    width: 87%; }

@media screen and (min-width: 641px) {
  .RaoHomeHeader {
    position: relative; }
    .RaoHomeHeader .RaoHomeHeader__FirstContainer {
      height: 100%;
      display: flex;
      align-items: center; }
      .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content {
        padding-left: 10%;
        padding-top: 0;
        max-width: 50%; }
        .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Title {
          font-weight: 600;
          font-size: 64px;
          line-height: 66px;
          letter-spacing: 1px; }
        .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper {
          margin-top: 56px; }
          .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper > .Button {
            width: 200px; }
    .RaoHomeHeader .RaoHomeHeader__SecondContainer {
      height: var(--secondContainerHeight); }
      .RaoHomeHeader .RaoHomeHeader__SecondContainer::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1; }
      .RaoHomeHeader .RaoHomeHeader__SecondContainer .RaoHomeHeader__SecondContainer_Wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 53%;
        height: 100%;
        padding-left: 8%; }
    .RaoHomeHeader .RaoTextWithImage__ImageContainer {
      position: absolute;
      width: 55%; } }
@media screen and (min-width: 1200px) {
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Title {
    font-size: 40px;
    line-height: 41px; }
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper {
    margin-top: 35px; } }
@media screen and (min-width: 1440px) {
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Title {
    font-size: 48px;
    line-height: 50px; }
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper {
    margin-top: 42px; } }
@media screen and (min-width: 1680px) {
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Title {
    font-size: 56px;
    line-height: 58px; }
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper {
    margin-top: 49px; } }
@media screen and (min-width: 1920px) {
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Title {
    font-size: 64px;
    line-height: 66px; }
  .RaoHomeHeader .RaoHomeHeader__FirstContainer .RaoHomeHeader__Content .RaoHomeHeader__Content_Button_Wraper {
    margin-top: 56px; } }
/**
 * ----------------------------------------------------------------------------
 * RaoHomeHeader block
 * ----------------------------------------------------------------------------
*/
.RaoInfoBlocks::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
.RaoInfoBlocks .RaoInfoBlocks_wrapper {
  display: flex;
  flex-direction: column;
  padding: 15% 0%; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper {
    padding-left: 8%;
    padding-bottom: 3%;
    padding-right: 8%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper .RaoInfoBlocks_titleContent {
      font-weight: 600;
      font-size: 24px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.451557px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper {
    padding-left: 8%;
    padding-bottom: 8%;
    padding-right: 8%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper .RaoInfoBlocks_subTitleContent {
      font-weight: 200;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.451557px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .Carousel__Cell {
    width: 58%; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .flickity-prev-next-button {
    width: 54px;
    height: 54px;
    background-color: var(--button-background);
    top: 40%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .flickity-prev-next-button > svg {
      fill: var(--button-text-color); }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .flickity-prev-next-button.previous {
      left: 2%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .flickity-prev-next-button.next {
      right: 2%; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_block {
    width: 100%;
    display: inline-block !important;
    padding: 0px 9%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_block .RaoInfoBlocks_block_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 160px;
      padding: 10%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_block .RaoInfoBlocks_block_title {
      font-weight: 600;
      font-size: 24px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.754629px;
      padding: 6% 0; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_block .RaoInfoBlocks_block_text {
      font-weight: 200;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      letter-spacing: 0.75463px; }

@media screen and (min-width: 641px) {
  .RaoInfoBlocks .RaoInfoBlocks_wrapper {
    display: flex;
    flex-direction: column;
    padding: 8% 8.5%; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper {
      padding: 0;
      padding-bottom: 3%; }
      .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper .RaoInfoBlocks_titleContent {
        font-weight: 400;
        font-size: 30px;
        line-height: 31px;
        text-align: center;
        letter-spacing: 1px; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper {
      padding: 0;
      padding-bottom: 3%; }
      .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper .RaoInfoBlocks_subTitleContent {
        font-weight: 200;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 1px; }
    .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper {
      display: flex;
      flex-direction: column; }
      .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row {
        display: flex;
        justify-content: space-between; }
        .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block {
          width: 28%;
          padding: 0; }
          .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: auto;
            padding: 10%; }
          .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_title {
            font-weight: 400;
            font-weight: 600;
            font-size: 20px;
            line-height: 31px;
            text-align: center;
            letter-spacing: 1px;
            padding: 0;
            padding-bottom: 3%; }
          .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_text {
            font-weight: 200;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 1px; } }
@media screen and (min-width: 1440px) {
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper .RaoInfoBlocks_titleContent {
    font-size: 36px;
    line-height: 38px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper .RaoInfoBlocks_subTitleContent {
    font-size: 24px;
    line-height: 32px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_title {
    font-size: 24px;
    line-height: 38px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_text {
    font-size: 14px;
    line-height: 18px; } }
@media screen and (min-width: 1680px) {
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper .RaoInfoBlocks_titleContent {
    font-size: 42px;
    line-height: 44px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper .RaoInfoBlocks_subTitleContent {
    font-size: 28px;
    line-height: 38px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_title {
    font-size: 28px;
    line-height: 44px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_text {
    font-size: 16px;
    line-height: 21px; } }
@media screen and (min-width: 1920px) {
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_titleWrapper .RaoInfoBlocks_titleContent {
    font-size: 48px;
    line-height: 50px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_subTitleWrapper .RaoInfoBlocks_subTitleContent {
    font-size: 32px;
    line-height: 43px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_title {
    font-size: 32px;
    line-height: 50px; }
  .RaoInfoBlocks .RaoInfoBlocks_wrapper .RaoInfoBlocks_blocksWrapper .RaoInfoBlocks_blocksWrapper_row .RaoInfoBlocks_block .RaoInfoBlocks_block_text {
    font-size: 18px;
    line-height: 24px; } }
/**
 * ----------------------------------------------------------------------------
 * RaoTextWithImage block
 * ----------------------------------------------------------------------------
*/
.RaoTextWithImage .RaoTextWithImage__Container {
  display: flex;
  width: 100%; }
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__ImageContainer_Wrapper img {
    height: auto; }
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper {
    width: 100%;
    display: flex;
    align-items: center; }
    .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content {
      margin: 0; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Title {
        font-weight: 600;
        padding-top: 10%;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 1px; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: 1px; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper {
        margin-top: 50px; }
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper > a {
          margin-left: auto;
          margin-right: auto; }
@media screen and (min-width: 641px) {
  .RaoTextWithImage .RaoTextWithImage__Container {
    flex-direction: row; }
    .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__ImageContainer {
      width: 50%;
      display: flex;
      position: relative; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__ImageContainer .RaoTextWithImage__ImageContainer_Wrapper {
        margin: 0; }
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__ImageContainer .RaoTextWithImage__ImageContainer_Wrapper > img {
          height: auto; }
    .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper {
      width: 50%;
      display: flex;
      align-items: center; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Title {
        padding-top: 0;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 31px;
        letter-spacing: 1px;
        text-align: left; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
        font-weight: 300;
        letter-spacing: 1px;
        text-align: left; }
      .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper {
        margin-top: 31px; }
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper > a {
          margin-left: 0; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
          font-size: calc(26px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
          font-size: calc(26px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
          font-size: calc(26px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
          font-size: calc(26px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Description {
          font-size: 26px;
          line-height: 34px; } }
@media screen and (min-width: 1200px) {
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Title {
    font-size: 30px;
    line-height: 31px; }
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper {
    margin-top: 31px; } }
@media screen and (min-width: 1440px) {
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Title {
    font-size: 36px;
    line-height: 38px; }
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper {
    margin-top: 38px; } }
@media screen and (min-width: 1680px) {
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Title {
    font-size: 42px;
    line-height: 44px; }
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper {
    margin-top: 44px; } }
@media screen and (min-width: 1920px) {
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Title {
    font-size: 48px;
    line-height: 50px; }
  .RaoTextWithImage .RaoTextWithImage__Container .RaoTextWithImage__Content_Wrapper .RaoTextWithImage__Content .RaoTextWithImage__Content_Button_Wraper {
    margin-top: 50px; } }

/**
 * ----------------------------------------------------------------------------
 * RAOConnections block
 * ----------------------------------------------------------------------------
*/
.RAOConnections {
  overflow: hidden; }
  .RAOConnections .RAOConnections_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16% 4.1%; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper {
      padding-bottom: 7%; }
      .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper .RAOConnections_titleContent {
        white-space: break-spaces;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 1px; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper {
      padding-bottom: 12%;
      padding-top: 3%; }
      .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper .RAOConnections_blockTextContent {
        white-space: break-spaces;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 1px; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections_blocksWrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1000px;
      width: 126%;
      padding-bottom: 12%; }
      .RAOConnections .RAOConnections_wrapper .RAOConnections_blocksWrapper .RAOConnections_block {
        width: 100%;
        max-width: 110px;
        padding: 5px;
        border-radius: 50%; }
        .RAOConnections .RAOConnections_wrapper .RAOConnections_blocksWrapper .RAOConnections_block .RAOConnections_blocksWrapper_image {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections__Button_Wraper {
      display: flex;
      justify-content: center;
      min-width: 200px; }

@media screen and (min-width: 641px) {
  .RAOConnections .RAOConnections_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7% 16%; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper {
      padding-bottom: 7%; }
      .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper .RAOConnections_titleContent {
        white-space: break-spaces;
        font-weight: 600;
        font-size: 30px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 1px; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper {
      padding-bottom: 7%;
      padding-top: 3%; }
      .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper .RAOConnections_blockTextContent {
        white-space: break-spaces;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 1px; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections_blocksWrapper {
      display: flex;
      justify-content: space-between;
      max-width: 1000px;
      width: 100%;
      padding-bottom: 4.8%; }
      .RAOConnections .RAOConnections_wrapper .RAOConnections_blocksWrapper .RAOConnections_block {
        width: 100%;
        max-width: 220px;
        padding: 10px;
        border-radius: 50%; }
        .RAOConnections .RAOConnections_wrapper .RAOConnections_blocksWrapper .RAOConnections_block .RAOConnections_blocksWrapper_image {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%; }
    .RAOConnections .RAOConnections_wrapper .RAOConnections__Button_Wraper {
      display: flex;
      justify-content: center;
      min-width: 300px; } }
@media screen and (min-width: 1440px) {
  .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper .RAOConnections_titleContent {
    font-size: 36px;
    line-height: 38px; }
  .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper .RAOConnections_blockTextContent {
    font-size: 24px;
    line-height: 26px; } }
@media screen and (min-width: 1680px) {
  .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper .RAOConnections_titleContent {
    font-size: 42px;
    line-height: 44px; }
  .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper .RAOConnections_blockTextContent {
    font-size: 28px;
    line-height: 30px; } }
@media screen and (min-width: 1920px) {
  .RAOConnections .RAOConnections_wrapper .RAOConnections_titleWrapper .RAOConnections_titleContent {
    font-size: 48px;
    line-height: 50px; }
  .RAOConnections .RAOConnections_wrapper .RAOConnections_blockTextWrapper .RAOConnections_blockTextContent {
    font-size: 32px;
    line-height: 34px; } }
/**
 * ----------------------------------------------------------------------------
 * RaoArticleItem block
 * ----------------------------------------------------------------------------
*/
.RaoArticleItem .RaoArticleItem_wrapper {
  padding: 13% 0; }
  .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper {
    padding-top: 2%;
    padding-bottom: 11%; }
    .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper .RaoArticleItem_titleContent {
      font-weight: 400;
      font-size: 24px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.451557px; }
  .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_blocksWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto; }
  .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem__Button_Wraper {
    display: flex;
    justify-content: center;
    padding-top: 11%; }
    .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem__Button_Wraper > a {
      max-width: 217px; }

@media screen and (min-width: 641px) {
  .RaoArticleItem .RaoArticleItem_wrapper {
    padding: 6% 0; }
    .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper {
      padding: 4% 0; }
      .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper .RaoArticleItem_titleContent {
        font-weight: 400;
        font-size: 30px;
        line-height: 31px;
        text-align: center;
        letter-spacing: 1px; }
    .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_blocksWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 6.3%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
    .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem__Button_Wraper {
      display: flex;
      justify-content: center;
      padding-top: 4.5%; }
      .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem__Button_Wraper > a {
        max-width: 298px; } }
@media screen and (min-width: 1440px) {
  .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper .RaoArticleItem_titleContent {
    font-size: 36px;
    line-height: 38px; } }
@media screen and (min-width: 1680px) {
  .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper .RaoArticleItem_titleContent {
    font-size: 42px;
    line-height: 44px; } }
@media screen and (min-width: 1920px) {
  .RaoArticleItem .RaoArticleItem_wrapper .RaoArticleItem_titleWrapper .RaoArticleItem_titleContent {
    font-size: 48px;
    line-height: 50px; } }
/**
 * ----------------------------------------------------------------------------
 * RAO Newsletter block
 * ----------------------------------------------------------------------------
*/
.RAONewsletter {
  display: flex;
  flex-direction: column;
  padding: 12% 4%;
  position: relative; }
  .RAONewsletter .RAONewsletter__textContainer {
    flex: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.462209px;
    color: #FFFFFF;
    padding: 0 8% 7%;
    z-index: 1; }
    .RAONewsletter .RAONewsletter__textContainer .RAONewsletter__backgroundFrame {
      width: 100%;
      display: flex; }
      .RAONewsletter .RAONewsletter__textContainer .RAONewsletter__backgroundFrame > svg {
        width: 100%; }
  .RAONewsletter .RAONewsletter__inputContainer {
    flex: 1;
    display: flex;
    align-items: center;
    z-index: 1; }
    .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 {
      width: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form {
        max-width: 100%;
        margin: 0;
        padding: 0 !important;
        background-color: transparent; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div:nth-child(1), .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div:nth-child(5), .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div:nth-child(6) {
          display: none; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div {
          flex-direction: column; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] {
            margin: 0;
            padding: 0;
            width: 100%;
            margin-bottom: 20px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(2):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(3):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(4) {
              width: 100%;
              min-width: 100%;
              margin-bottom: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(4) > div {
                width: 100%; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(4) button {
                height: 58px;
                width: 100%; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] > div {
              margin: 0;
              padding: 0; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] input {
              width: 100% !important;
              height: 58px !important;
              min-width: 100% !important;
              background: #fcfcfc !important;
              border: 2px solid #d6874f !important;
              box-sizing: border-box !important;
              border-radius: 58px !important;
              padding: 0px 0px 0px 60px !important; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] button {
              margin: 0 !important;
              min-width: 200px !important;
              font-size: 20px !important;
              font-family: var(--text-font-family) !important;
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              border-color: #d6874f !important;
              color: #ffffff !important;
              background-color: #d6874f !important;
              transform: none !important;
              border-radius: 40px !important;
              height: 100%; }
    .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 {
      width: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form {
        max-width: 100%;
        margin: 0;
        padding: 0 !important;
        background-color: transparent; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div:nth-child(1), .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div:nth-child(2) {
          display: none; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div {
          flex-direction: column; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] {
            margin: 0;
            padding: 0;
            width: 100%;
            margin-bottom: 20px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(3):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(4):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(5) {
              width: 100%;
              min-width: 100%;
              margin-bottom: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(5) > div {
                width: 100%; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(5) button {
                height: 58px;
                width: 100%; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] > div {
              margin: 0;
              padding: 0; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] input {
              width: 100% !important;
              height: 58px !important;
              min-width: 100% !important;
              background: #fcfcfc !important;
              border: 2px solid #d6874f !important;
              box-sizing: border-box !important;
              border-radius: 58px !important;
              padding: 0px 0px 0px 60px !important; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] button {
              margin: 0 !important;
              min-width: 200px !important;
              font-size: 20px !important;
              font-family: var(--text-font-family) !important;
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              border-color: #d6874f !important;
              color: #ffffff !important;
              background-color: #d6874f !important;
              transform: none !important;
              border-radius: 40px !important;
              height: 100%; }
    .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 {
      width: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form {
        max-width: 100%;
        margin: 0;
        padding: 0 !important;
        background-color: transparent; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div:nth-child(1) {
          display: none; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div {
          flex-direction: column; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] {
            margin: 0;
            padding: 0;
            width: 100%;
            margin-bottom: 20px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(2):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(3):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(4) {
              width: 100%;
              min-width: 100%;
              margin-bottom: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(4) > div {
                width: 100%; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(4) button {
                height: 58px;
                width: 100%; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] > div {
              margin: 0;
              padding: 0; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] input {
              width: 100% !important;
              height: 58px !important;
              min-width: 100% !important;
              background: #fcfcfc !important;
              border: 2px solid #d6874f !important;
              box-sizing: border-box !important;
              border-radius: 58px !important;
              padding: 0px 0px 0px 60px !important; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] button {
              margin: 0 !important;
              min-width: 200px !important;
              font-size: 20px !important;
              font-family: var(--text-font-family) !important;
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              border-color: #d6874f !important;
              color: #ffffff !important;
              background-color: #d6874f !important;
              transform: none !important;
              border-radius: 40px !important;
              height: 100%; }
    .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 {
      width: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form {
        max-width: 100%;
        margin: 0;
        padding: 0 !important;
        background-color: transparent; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div {
          flex-direction: column; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] {
            margin: 0;
            padding: 0;
            width: 100%;
            margin-bottom: 20px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(1):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(2):before {
              content: "";
              display: block;
              background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
              background-size: contain;
              background-repeat: no-repeat;
              width: 24px;
              height: 22px;
              float: left;
              margin-left: 10px;
              z-index: 1;
              position: absolute;
              top: 17px;
              left: 15px; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(3) {
              width: 100%;
              min-width: 100%;
              margin-bottom: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(3) > div {
                width: 100%; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(3) button {
                height: 58px;
                width: 100%; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] > div {
              margin: 0;
              padding: 0; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] input {
              width: 100% !important;
              height: 58px !important;
              min-width: 100% !important;
              background: #fcfcfc !important;
              border: 2px solid #d6874f !important;
              box-sizing: border-box !important;
              border-radius: 58px !important;
              padding: 0px 0px 0px 60px !important; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] button {
              margin: 0 !important;
              min-width: 200px !important;
              font-size: 20px !important;
              font-family: var(--text-font-family) !important;
              display: flex !important;
              align-items: center !important;
              justify-content: center !important;
              border-color: #d6874f !important;
              color: #ffffff !important;
              background-color: #d6874f !important;
              transform: none !important;
              border-radius: 40px !important;
              height: 100%; }
    .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner {
      display: flex;
      flex-direction: column; }
      .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper {
        width: 100%;
        height: 64px;
        position: relative;
        margin-bottom: 20px; }
        .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input {
          width: 100%;
          height: 100%;
          background: #FCFCFC;
          border: 2px solid #D6874F;
          box-sizing: border-box;
          border-radius: 58px;
          padding-left: 60px; }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input::placeholder {
            color: #A0A3BD;
            opacity: 1;
            line-height: 1em; }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input:active {
            border-color: var(--button-background); }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input:focus {
            border-color: var(--button-background); }
        .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input_icon {
          position: absolute;
          width: 60px;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 3%; }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input_icon > svg {
            height: 20px; }
      .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__Submit {
        max-width: 100%;
        height: 64px;
        margin-top: 0;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__Submit::after {
          content: "Send \2192"; }

@media screen and (min-width: 641px) {
  .RAONewsletter {
    display: flex;
    flex-direction: row;
    padding: 5% 9.5%;
    position: relative; }
    .RAONewsletter .RAONewsletter__textContainer {
      width: 40%;
      flex: none;
      font-weight: 600;
      font-size: 20px;
      line-height: 21px;
      letter-spacing: 1px;
      color: #FFFFFF;
      z-index: 1;
      padding: 0; }
      .RAONewsletter .RAONewsletter__textContainer .RAONewsletter__textContent {
        padding-right: 10%; }
      .RAONewsletter .RAONewsletter__textContainer .RAONewsletter__backgroundFrame {
        width: 100%;
        display: flex;
        margin-top: 10px; }
        .RAONewsletter .RAONewsletter__textContainer .RAONewsletter__backgroundFrame > svg {
          width: 100%; }
    .RAONewsletter .RAONewsletter__inputContainer {
      width: 60%;
      display: flex;
      align-items: center;
      z-index: 1; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 {
        width: 100%; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form {
          max-width: 100%;
          margin: 0;
          padding: 0 !important;
          background-color: transparent; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div:nth-child(1), .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div:nth-child(5), .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div:nth-child(6) {
            display: none; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div {
            flex-direction: row; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] {
              margin: 0;
              padding: 0;
              width: 100%;
              margin-right: 16px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(2):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(3):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(4) {
                width: auto;
                min-width: auto;
                margin-right: 0; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(4) > div {
                  width: auto; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"]:nth-child(4) button {
                  height: 58px;
                  width: auto; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] > div {
                margin: 0;
                padding: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] input {
                width: 100% !important;
                height: 58px !important;
                min-width: 100% !important;
                background: #fcfcfc !important;
                border: 2px solid #d6874f !important;
                box-sizing: border-box !important;
                border-radius: 58px !important;
                padding: 0px 0px 0px 60px !important; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_6 form > div > div[data-testid="form-row"] button {
                margin: 0 !important;
                min-width: 200px !important;
                font-size: 20px !important;
                font-family: var(--text-font-family) !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                border-color: #d6874f !important;
                color: #ffffff !important;
                background-color: #d6874f !important;
                transform: none !important;
                border-radius: 40px !important;
                height: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 {
        width: 100%; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form {
          max-width: 100%;
          margin: 0;
          padding: 0 !important;
          background-color: transparent; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div:nth-child(1), .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div:nth-child(2) {
            display: none; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div {
            flex-direction: row; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] {
              margin: 0;
              padding: 0;
              width: 100%;
              margin-right: 16px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(3):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(4):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(5) {
                width: auto;
                min-width: auto;
                margin-right: 0; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(5) > div {
                  width: auto; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"]:nth-child(5) button {
                  height: 58px;
                  width: auto; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] > div {
                margin: 0;
                padding: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] input {
                width: 100% !important;
                height: 58px !important;
                min-width: 100% !important;
                background: #fcfcfc !important;
                border: 2px solid #d6874f !important;
                box-sizing: border-box !important;
                border-radius: 58px !important;
                padding: 0px 0px 0px 60px !important; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_5 form > div > div[data-testid="form-row"] button {
                margin: 0 !important;
                min-width: 200px !important;
                font-size: 20px !important;
                font-family: var(--text-font-family) !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                border-color: #d6874f !important;
                color: #ffffff !important;
                background-color: #d6874f !important;
                transform: none !important;
                border-radius: 40px !important;
                height: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 {
        width: 100%; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form {
          max-width: 100%;
          margin: 0;
          padding: 0 !important;
          background-color: transparent; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div:nth-child(1) {
            display: none; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div {
            flex-direction: row; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] {
              margin: 0;
              padding: 0;
              width: 100%;
              margin-right: 16px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(2):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(3):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(4) {
                width: auto;
                min-width: auto;
                margin-right: 0; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(4) > div {
                  width: auto; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"]:nth-child(4) button {
                  height: 58px;
                  width: auto; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] > div {
                margin: 0;
                padding: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] input {
                width: 100% !important;
                height: 58px !important;
                min-width: 100% !important;
                background: #fcfcfc !important;
                border: 2px solid #d6874f !important;
                box-sizing: border-box !important;
                border-radius: 58px !important;
                padding: 0px 0px 0px 60px !important; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_4 form > div > div[data-testid="form-row"] button {
                margin: 0 !important;
                min-width: 200px !important;
                font-size: 20px !important;
                font-family: var(--text-font-family) !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                border-color: #d6874f !important;
                color: #ffffff !important;
                background-color: #d6874f !important;
                transform: none !important;
                border-radius: 40px !important;
                height: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 {
        width: 100%; }
        .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form {
          max-width: 100%;
          margin: 0;
          padding: 0 !important;
          background-color: transparent; }
          .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div {
            flex-direction: row; }
            .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] {
              margin: 0;
              padding: 0;
              width: 100%;
              margin-right: 16px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(1):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/user_name.svg?v=6961048593877531368");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(2):before {
                content: "";
                display: block;
                background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/email.svg?v=10569612644129855019");
                background-size: contain;
                background-repeat: no-repeat;
                width: 24px;
                height: 22px;
                float: left;
                margin-left: 10px;
                z-index: 1;
                position: absolute;
                top: 17px;
                left: 15px; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(3) {
                width: auto;
                min-width: auto;
                margin-right: 0; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(3) > div {
                  width: auto; }
                .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"]:nth-child(3) button {
                  height: 58px;
                  width: auto; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] > div {
                margin: 0;
                padding: 0; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] input {
                width: 100% !important;
                height: 58px !important;
                min-width: 100% !important;
                background: #fcfcfc !important;
                border: 2px solid #d6874f !important;
                box-sizing: border-box !important;
                border-radius: 58px !important;
                padding: 0px 0px 0px 60px !important; }
              .RAONewsletter .RAONewsletter__inputContainer .signup_button_container_3 form > div > div[data-testid="form-row"] button {
                margin: 0 !important;
                min-width: 200px !important;
                font-size: 20px !important;
                font-family: var(--text-font-family) !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
                border-color: #d6874f !important;
                color: #ffffff !important;
                background-color: #d6874f !important;
                transform: none !important;
                border-radius: 40px !important;
                height: 100%; }
      .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner {
        display: flex;
        flex-direction: row; }
        .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper {
          width: 100%;
          height: 64px;
          position: relative;
          margin-right: 16px; }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input {
            width: 100%;
            height: 100%;
            min-width: auto;
            background: #FCFCFC;
            border: 2px solid #D6874F;
            box-sizing: border-box;
            border-radius: 58px; }
            .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input::placeholder {
              color: #A0A3BD;
              opacity: 1;
              line-height: 1em; }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__InputWrapper .Form__Input_icon {
            position: absolute;
            width: 72px;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 3%; }
        .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__Submit {
          margin: 0;
          max-width: 142px;
          min-width: 142px;
          font-size: 22px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .RAONewsletter .RAONewsletter__inputContainer .RAONewsletter__Inner .Form__Submit::after {
            content: "\2192"; } }
@media screen and (min-width: 1440px) {
  .RAONewsletter .RAONewsletter__textContainer {
    font-size: 24px;
    line-height: 26px; } }
@media screen and (min-width: 1680px) {
  .RAONewsletter .RAONewsletter__textContainer {
    font-size: 28px;
    line-height: 30px; } }
@media screen and (min-width: 1920px) {
  .RAONewsletter .RAONewsletter__textContainer {
    font-size: 32px;
    line-height: 34px; } }
/**
 * ----------------------------------------------------------------------------
 * Custom product styles
 * ----------------------------------------------------------------------------
*/
.Product {
  margin: 10% 3.8%; }
  .Product .ProductMeta__PriceList2 {
    display: flex;
    margin-bottom: 15px;
    margin-top: 0;
    align-items: center; }
    .Product .ProductMeta__PriceList2 .ProductMeta__Price2_title {
      font-weight: 400;
      font-size: 18px;
      line-height: 34px;
      letter-spacing: 1px;
      margin-right: 10px; }
    .Product .ProductMeta__PriceList2 .ProductMeta__Price2 {
      font-weight: 600;
      color: #007F7B;
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 1px; }
    .Product .ProductMeta__PriceList2 .Price--compareAt {
      font-weight: 300;
      font-size: 18px;
      line-height: 34px;
      letter-spacing: 0.75px;
      text-decoration-line: line-through;
      color: var(--text-color);
      margin-left: 20px; }
  .Product .Product__Wrapper .Product__Gallery {
    margin: 0; }
    .Product .Product__Wrapper .Product__Gallery .Product__Slideshow {
      width: 100%;
      max-width: 100%; }
    .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px; }
      .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNav {
        display: flex;
        width: calc(100% - 120px); }
        .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNav .swiper-slide-thumb-active img {
          border: 2px solid var(--heading-color); }
      .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: var(--button-background);
        bottom: calc(50% - 16px);
        z-index: 1; }
        .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow > svg {
          color: #f7f7fc;
          stroke-width: 2px;
          width: auto;
          height: 10px; }
        .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--previous {
          margin: 0;
          left: 2%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--previous::before {
            content: none; }
        .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--next {
          margin: 0;
          right: 2%;
          display: flex;
          justify-content: center;
          align-items: center; }
          .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--next::before {
            content: none; }
  .Product .Product__Wrapper .Product__Gallery--withThumbnails .Product__SlideshowNav--dots {
    display: none; }
  .Product .Product__Wrapper .Product__Gallery--withThumbnails .Product__SlideshowNav--thumbnails {
    display: block; }
  .Product .Product__Wrapper .Product__Gallery:not(.Product__Gallery--stack).Product__Gallery--withThumbnails .Product__SlideshowNav {
    margin: 0; }
  .Product .Product__Wrapper .Product__Gallery:not(.Product__Gallery--stack).Product__Gallery--withThumbnails .flickity-page-dots {
    display: none; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info {
    margin: 0;
    width: 100%;
    max-width: 100%; }
    .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container {
      padding: 0; }
      .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        letter-spacing: 1px;
        max-width: 90%;
        text-align: left;
        margin-top: 9%; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title .ProductMeta__Title_Sub {
          font-weight: 200; }
      .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__PriceList {
        display: flex; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__PriceList .ProductMeta__Price {
          font-weight: 600;
          color: #007F7B;
          font-size: 24px;
          line-height: 34px;
          letter-spacing: 1px; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__PriceList .Price--compareAt {
          font-weight: 300;
          font-size: 18px;
          line-height: 34px;
          letter-spacing: 0.75px;
          text-decoration-line: line-through;
          color: var(--text-color);
          margin-left: 20px; }
      .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews {
        display: flex;
        margin-top: 10px; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-star {
          color: #FED30E;
          font-size: 18px; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
          margin-left: 8px;
          font-weight: 200;
          font-size: 16px;
          line-height: 34px; }
      .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
        padding-top: 0;
        border-top: none;
        font-weight: 200;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.745924px; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description ul {
          list-style: none;
          padding-left: 1em;
          margin: 0;
          padding: 0; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description li {
          position: relative;
          padding: 0;
          padding-left: 24px; }
          .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description li:before {
            content: '';
            display: inline-block;
            height: 1em;
            width: 1em;
            background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/background_frame.svg?v=864079558124847358");
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 0.5em;
            position: absolute;
            left: 0;
            top: 0; }
  .Product .Product__Wrapper .Product__InfoWrapper .ProductForm {
    margin-top: 0; }
  .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .ProductForm__Label {
    display: none; }
  .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList {
    margin: 0; }
    .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item {
      margin: 0;
      width: 100%; }
      .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
        display: flex;
        align-items: center;
        padding: 1% 0;
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        text-align: left; }
        .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label:before {
          content: '';
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
          min-width: 32px;
          background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/radio_not_active.svg?v=11984956148192186917");
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 15px; }
      .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item input[type=radio]:checked + label {
        font-weight: 600; }
        .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item input[type=radio]:checked + label:before {
          background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/radio_active.svg?v=9316403731789145192"); }
      .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item > .SizeSwatch {
        border: none; }
  .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .ProductForm__Label {
      display: none; }
    .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .ProductForm__QuantitySelector {
      margin-bottom: 8%; }
    .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .ProductForm__AddToCart {
      height: 64px;
      max-width: 380px;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.75px; }
    .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .Button--primary::after {
      content: "";
      display: block;
      background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy.svg?v=711253759822625200");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 22px;
      float: left;
      margin-left: 10px; }
    .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .Button--secondary::after {
      content: "";
      display: block;
      background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy_secondary.svg?v=12974676526321600639");
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px;
      height: 22px;
      float: left;
      margin-left: 10px; }

.ProductReview_Container {
  padding: 10% 0;
  background: linear-gradient(0deg, #fbf0e9 0%, rgba(251, 240, 233, 0) 100%); }
  .ProductReview_Container .ProductReview_Container_title {
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 4%; }
  .ProductReview_Container .jdgm-rev-widg {
    border: none;
    padding: 0 4%; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__header {
      display: none; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
      padding: 10%;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
      backdrop-filter: blur(46px);
      margin-top: 16px;
      border-radius: 32px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, auto); }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        display: grid;
        grid-template: "a b" "a c";
        width: fit-content; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
          grid-area: a;
          width: 56px;
          height: 56px;
          line-height: 56px;
          margin-right: 17px;
          font-size: 20px; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon::after {
            display: none; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating {
          grid-area: c;
          display: inline-block; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating .jdgm-star {
            font-size: 18px;
            color: #FED30E; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
          display: none; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper {
          display: none; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper {
          grid-area: b;
          display: flex;
          flex-direction: column; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
            font-weight: 600;
            font-size: 18px;
            line-height: 16px;
            letter-spacing: 0.435677px; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
            font-weight: 200;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.44px;
            opacity: 1; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 1;
        grid-row-end: 1; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
          font-weight: 600;
          font-size: 32px;
          line-height: 1em;
          letter-spacing: 1px;
          padding-bottom: 5%; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
          font-weight: 200;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 1px;
          padding-bottom: 7%; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__pics > a {
          margin-bottom: 3%; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__actions {
        display: none; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate {
      padding-top: 4%; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__page {
        font-weight: 200;
        font-size: 24px;
        line-height: 28px; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-curt {
        font-weight: 600;
        font-size: 36px;
        line-height: 28px; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__last-page,
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__first-page,
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__next-page,
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__prev-page {
        font-family: 'JudgemeIcons';
        width: 54px;
        height: 54px;
        border-radius: 26px;
        background-color: var(--button-background);
        padding: 0;
        margin: 0px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: var(--button-text-color);
        font-size: 24px; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__last-page:hover,
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__first-page:hover,
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__next-page:hover,
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__prev-page:hover {
          opacity: 0.8; }

.RaoProductService_blocksWrapper {
  display: flex;
  flex-direction: column;
  padding: 0 3.8% 4.2%; }
  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row {
    display: flex;
    justify-content: space-between; }
    .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block {
      width: 32%;
      padding: 0; }
      .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: auto;
        padding: 20%;
        padding-bottom: 10%; }
      .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_title {
        font-weight: 400;
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
        text-align: center;
        letter-spacing: 1px;
        padding: 0;
        padding-bottom: 3%; }
      .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_text {
        font-weight: 200;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.457895px; }

@media screen and (min-width: 641px) {
  .Product {
    max-width: 100%;
    margin: 4.3% 9.5%; }
    .Product .Product__Wrapper {
      width: 47%; }
      .Product .Product__Wrapper .Product__Gallery {
        margin: 0; }
        .Product .Product__Wrapper .Product__Gallery .Product__Slideshow {
          width: 100%;
          max-width: 100%; }
        .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 10px; }
          .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNav {
            display: flex;
            width: calc(100% - 120px); }
            .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNav .swiper-slide-thumb-active img {
              border: 2px solid var(--heading-color); }
          .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow {
            position: relative;
            width: 40px;
            height: 40px;
            border-radius: 30px;
            background-color: var(--button-background);
            bottom: calc(50% - 30px);
            z-index: 1; }
            .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow > svg {
              color: #f7f7fc;
              stroke-width: 2px;
              width: auto;
              height: 12px; }
            .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--previous {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center; }
              .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--previous::before {
                content: none; }
            .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--next {
              margin: 0;
              display: flex;
              justify-content: center;
              align-items: center; }
              .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow.Product__SlideshowNavArrow--next::before {
                content: none; }
      .Product .Product__Wrapper .Product__InfoWrapper {
        width: 48%; }
        .Product .Product__Wrapper .Product__InfoWrapper .Product__Info {
          margin: 0;
          width: 100%;
          max-width: 100%; }
          .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container {
            padding: 0 24px; }
            .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
              font-weight: 600;
              letter-spacing: 1px;
              max-width: 100%;
              margin-top: 0%; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title .ProductMeta__Title_Sub {
                font-weight: 200; }
            .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__PriceList {
              display: flex; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__PriceList .ProductMeta__Price {
                font-weight: 600;
                color: #007F7B;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 1px; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__PriceList .Price--compareAt {
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.75px;
                text-decoration-line: line-through;
                color: var(--text-color);
                margin-left: 20px; }
            .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews {
              margin-top: 3%; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-star {
                color: #FED30E;
                font-size: 20px; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
                margin-left: 13px;
                font-weight: 200;
                letter-spacing: 0.75px; }
            .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
              padding-top: 0;
              border-top: none;
              font-weight: 200;
              letter-spacing: 0.75px; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description ul {
                list-style: none;
                padding-left: 1em;
                margin: 0;
                padding: 0; }
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description li {
                margin-left: 0; }
                .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description li:before {
                  content: '';
                  display: inline-block;
                  height: 1em;
                  width: 1em;
                  background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/background_frame.svg?v=864079558124847358");
                  background-size: contain;
                  background-repeat: no-repeat;
                  margin-right: 0.5em; }
        .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .ProductForm__Label {
          display: none; }
        .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList {
          margin: 0; }
          .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item {
            margin: 0;
            width: 100%; }
            .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
              display: flex;
              align-items: center;
              padding: 1% 0;
              font-weight: 200;
              letter-spacing: 0.75px; }
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label:before {
                content: '';
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/radio_not_active.svg?v=11984956148192186917");
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: 15px; }
            .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item input[type=radio]:checked + label {
              font-weight: 600; }
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item input[type=radio]:checked + label:before {
                background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/radio_active.svg?v=9316403731789145192"); }
            .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item > .SizeSwatch {
              border: none; }
        .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper {
          display: flex;
          align-items: center;
          flex-direction: row; }
          .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .ProductForm__Label {
            display: none; }
          .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .ProductForm__QuantitySelector {
            margin-right: 10%;
            margin-bottom: 0; }
          .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .ProductForm__AddToCart {
            max-width: 260px; }
          .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .Button--primary::after {
            content: "";
            display: block;
            background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy.svg?v=711253759822625200");
            background-size: contain;
            background-repeat: no-repeat;
            width: 24px;
            height: 22px;
            float: left;
            margin-left: 10px; }
          .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__SubmitButtonWraper .Button--secondary::after {
            content: "";
            display: block;
            background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy_secondary.svg?v=12974676526321600639");
            background-size: contain;
            background-repeat: no-repeat;
            width: 24px;
            height: 22px;
            float: left;
            margin-left: 10px; }

  .ProductReview_Container {
    padding: 4% 0; }
    .ProductReview_Container .ProductReview_Container_title {
      font-size: 30px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 1px;
      padding-bottom: 4%; }
    .ProductReview_Container .jdgm-rev-widg {
      border: none;
      padding: 0 9.5%; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__header {
        display: none; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
        padding: 3% 8%;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
        backdrop-filter: blur(46px);
        margin-top: 15px;
        border-radius: 20px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, auto); }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header {
          display: block;
          width: 100%;
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 2;
          grid-row-end: 2; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
            width: 100px;
            height: 100px;
            line-height: 100px;
            margin-right: 14px;
            font-size: 19px; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating {
            margin-top: 8px;
            display: inline-block; }
            .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating .jdgm-star {
              font-size: 20px; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
            display: inline;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 0.75px;
            opacity: 1;
            margin-left: 10px; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__br {
            padding-bottom: 0.5%; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper {
            display: inline-flex;
            flex-direction: row;
            height: 32px;
            align-items: center;
            margin-left: 10px; }
            .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
              font-weight: 600;
              font-size: 16px;
              line-height: 12px;
              letter-spacing: 0.435677px; }
            .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
              font-size: 14px;
              line-height: 16;
              letter-spacing: 0.44px;
              color: var(--text-color);
              font-weight: 200;
              margin-left: 5px;
              opacity: 1; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper {
            display: inline-flex;
            height: 32px;
            align-items: center; }
            .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge {
              background: var(--text-color);
              border-radius: 25px;
              padding: 7px 10px;
              position: relative;
              top: -3px; }
              .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge::before {
                letter-spacing: 0.44px; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content {
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 1;
          grid-row-end: 1; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
            font-weight: 600;
            font-size: 30px;
            line-height: 32px;
            letter-spacing: 1px;
            padding-bottom: 1.5%; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
            font-weight: 200;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.75px;
            padding-bottom: 3%; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__pics > a {
            margin-bottom: 3%; }
      .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate {
        padding-top: 4%; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__page {
          font-weight: 200;
          font-size: 16px;
          line-height: 18px; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-curt {
          font-weight: 600;
          font-size: 22px;
          line-height: 20px; }
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__last-page,
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__first-page,
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__next-page,
        .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__prev-page {
          font-family: 'JudgemeIcons';
          width: 54px;
          height: 54px;
          border-radius: 26px;
          background-color: var(--button-background);
          padding: 0;
          margin: 0px 10px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: var(--button-text-color);
          font-size: 24px; }
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__last-page:hover,
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__first-page:hover,
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__next-page:hover,
          .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__prev-page:hover {
            opacity: 0.8; }

  .RaoProductService_blocksWrapper {
    display: flex;
    flex-direction: column;
    padding: 0 18% 4.2%; }
    .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row {
      display: flex;
      justify-content: space-between; }
      .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block {
        width: 28%;
        padding: 0; }
        .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: auto;
          padding: 10%; }
        .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_title {
          font-weight: 400;
          font-weight: 600;
          font-size: 20px;
          line-height: 31px;
          text-align: center;
          letter-spacing: 1px;
          padding: 0;
          padding-bottom: 3%; }
        .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_text {
          font-weight: 200;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 1px; }
          .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_text > p {
            margin: 0; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
                font-size: calc(48px * 0.62);
                line-height: calc(50px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
                font-size: calc(48px * 0.62);
                line-height: calc(50px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
                font-size: calc(48px * 0.75);
                line-height: calc(50px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
                font-size: calc(48px * 0.875);
                line-height: calc(50px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Title {
                font-size: 48px;
                line-height: 50px; } }
              @media screen and (min-width: 641px) and (min-width: 641px) {
                .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
                  font-size: calc(18px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1140px) {
                .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
                  font-size: calc(18px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1440px) {
                .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
                  font-size: calc(18px * 0.75);
                  line-height: calc(34px * 0.75); } }
              @media screen and (min-width: 641px) and (min-width: 1680px) {
                .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
                  font-size: calc(18px * 0.875);
                  line-height: calc(34px * 0.875); } }
              @media screen and (min-width: 641px) and (min-width: 1920px) {
                .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Reviews .jdgm-prev-badge__text {
                  font-size: 18px;
                  line-height: 34px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
                font-size: calc(24px * 0.62);
                line-height: calc(28px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
                font-size: calc(24px * 0.62);
                line-height: calc(28px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
                font-size: calc(24px * 0.75);
                line-height: calc(28px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
                font-size: calc(24px * 0.875);
                line-height: calc(28px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .Container .ProductMeta .ProductMeta__Description {
                font-size: 24px;
                line-height: 28px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
                font-size: calc(24px * 0.62);
                line-height: calc(38px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
                font-size: calc(24px * 0.62);
                line-height: calc(38px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
                font-size: calc(24px * 0.75);
                line-height: calc(38px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
                font-size: calc(24px * 0.875);
                line-height: calc(38px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .Product .Product__Wrapper .Product__InfoWrapper .ProductForm__Variants .ProductForm__Option .SizeSwatchList .HorizontalList__Item label {
                font-size: 24px;
                line-height: 38px; } }
              @media screen and (min-width: 641px) and (min-width: 641px) {
                .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge::before {
                  font-size: calc(18px * 0.62);
                  line-height: calc(21px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1140px) {
                .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge::before {
                  font-size: calc(18px * 0.62);
                  line-height: calc(21px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1440px) {
                .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge::before {
                  font-size: calc(18px * 0.75);
                  line-height: calc(21px * 0.75); } }
              @media screen and (min-width: 641px) and (min-width: 1680px) {
                .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge::before {
                  font-size: calc(18px * 0.875);
                  line-height: calc(21px * 0.875); } }
              @media screen and (min-width: 641px) and (min-width: 1920px) {
                .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper .jdgm-rev__buyer-badge::before {
                  font-size: 18px;
                  line-height: 21px; } }
@media screen and (min-width: 1440px) {
  .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container {
    margin-top: 30px; }
    .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNav {
      width: calc(100% - 180px); }
    .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow {
      width: 40px;
      height: 40px; }
      .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow > svg {
        height: 14px; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .ProductMeta .ProductMeta__PriceList .ProductMeta__Price {
    font-size: 24px;
    line-height: 26px; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .ProductMeta .ProductMeta__PriceList .Price--compareAt {
    font-size: 14px;
    line-height: 26px;
    margin-left: 15px; }

  .ProductReview_Container .ProductReview_Container_title {
    font-size: 26px;
    line-height: 38px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
    backdrop-filter: blur(35px);
    margin-top: 18px;
    border-radius: 24px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
      font-size: 23px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
      font-size: 14px;
      line-height: 26px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
      font-size: 18px;
      line-height: 12px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
      font-size: 14px;
      line-height: 16px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
      font-size: 36px;
      line-height: 38px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
      font-size: 18px;
      line-height: 29px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__page {
    font-size: 18px;
    line-height: 21px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-curt {
    font-size: 27px;
    line-height: 21px; }

  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_title {
    font-size: 24px;
    line-height: 38px; }
  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_text {
    font-size: 14px;
    line-height: 18px; } }
@media screen and (min-width: 1680px) {
  .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container {
    margin-top: 35px; }
    .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow {
      width: 60px;
      height: 60px; }
      .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container .Product__SlideshowNavArrow > svg {
        height: 16px; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .ProductMeta .ProductMeta__PriceList .ProductMeta__Price {
    font-size: 28px;
    line-height: 30px; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .ProductMeta .ProductMeta__PriceList .Price--compareAt {
    font-size: 16px;
    line-height: 30px;
    margin-left: 18px; }

  .ProductReview_Container .ProductReview_Container_title {
    font-size: 42px;
    line-height: 44px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
    backdrop-filter: blur(40px);
    margin-top: 21px;
    border-radius: 28px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
      font-size: 26px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
      font-size: 16px;
      line-height: 30px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
      font-size: 21px;
      line-height: 14px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
      font-size: 16px;
      line-height: 19px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
      font-size: 42px;
      line-height: 44px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
      font-size: 21px;
      line-height: 34px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__page {
    font-size: 21px;
    line-height: 25px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-curt {
    font-size: 32px;
    line-height: 25px; }

  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_title {
    font-size: 28px;
    line-height: 44px; }
  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_text {
    font-size: 16px;
    line-height: 21px; } }
@media screen and (min-width: 1920px) {
  .Product .Product__Wrapper .Product__Gallery .Product__SlideshowNav_Container {
    margin-top: 40px; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .ProductMeta .ProductMeta__PriceList .ProductMeta__Price {
    font-size: 32px;
    line-height: 34px; }
  .Product .Product__Wrapper .Product__InfoWrapper .Product__Info .ProductMeta .ProductMeta__PriceList .Price--compareAt {
    font-size: 18px;
    line-height: 34px;
    margin-left: 20px; }

  .ProductReview_Container .ProductReview_Container_title {
    font-size: 48px;
    line-height: 50px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
    backdrop-filter: blur(46px);
    margin-top: 24px;
    border-radius: 32px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
      font-size: 30px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
      font-size: 18px;
      line-height: 34px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
      font-size: 24px;
      line-height: 16px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
      font-size: 18px;
      line-height: 20.95px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
      font-size: 48px;
      line-height: 50px; }
    .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
      font-size: 24px;
      line-height: 38px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-paginate__page {
    font-size: 24px;
    line-height: 28px; }
  .ProductReview_Container .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate .jdgm-curt {
    font-size: 36px;
    line-height: 28px; }

  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_title {
    font-size: 32px;
    line-height: 50px; }
  .RaoProductService_blocksWrapper .RaoProductService_blocksWrapper_row .RaoProductService_block .RaoProductService_block_text {
    font-size: 18px;
    line-height: 24px; } }
/**
 * ----------------------------------------------------------------------------
 * Rao Product Reviews block styles
 * ----------------------------------------------------------------------------
*/
.RaoProductReviews {
  display: block;
  width: 100%;
  padding: 17% 0; }
  .RaoProductReviews .RaoProductReviews_title {
    font-size: 24px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.451557px;
    padding-bottom: 9%; }
  .RaoProductReviews .RaoProductReviews_slide {
    padding: 9% 5.5%;
    display: flex;
    flex-direction: column; }
    .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header {
      width: 100%; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_title {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 1px; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer {
        margin-top: 2%; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
          font-weight: 600;
          font-size: 20px;
          line-height: 28px;
          letter-spacing: 1px; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
          font-weight: 200;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.75px; }
    .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
        font-weight: 200;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        padding: 30px 0; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user {
        display: flex;
        align-items: center; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_imageContainer {
          width: 56px;
          height: 56px;
          margin: 0;
          margin-right: 2%; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_imageContainer > img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_name {
          font-weight: 600;
          font-size: 18px;
          line-height: 16px;
          letter-spacing: 0.435677px; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_position {
          font-weight: 200;
          font-size: 14px;
          line-height: 15.5px;
          letter-spacing: 0.44px;
          padding-bottom: 3%; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg {
          height: 17px;
          width: 17px; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg:not(:last-child) {
          margin-right: 5px; }
  .RaoProductReviews .RaoProductReviews_slide_c1, .RaoProductReviews .RaoProductReviews_slide_alt_c1 {
    background: rgba(62, 58, 180, 0.5);
    box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
    backdrop-filter: blur(46px);
    border-radius: 32px;
    color: var(--secondary-elements-text-color); }
  .RaoProductReviews .RaoProductReviews_slide_c2, .RaoProductReviews .RaoProductReviews_slide_c2 {
    background: rgba(255, 192, 147, 0.5);
    box-shadow: 0px 4px 4px rgba(180, 154, 136, 0.21);
    backdrop-filter: blur(46px);
    border-radius: 32px; }
    .RaoProductReviews .RaoProductReviews_slide_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg > path, .RaoProductReviews .RaoProductReviews_slide_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg > path {
      fill: #D6874F; }
  .RaoProductReviews .RaoProductReviews_slide_c3, .RaoProductReviews .RaoProductReviews_slide_c3 {
    background: rgba(77, 204, 200, 0.5);
    box-shadow: 0px 4px 4px rgba(129, 139, 136, 0.25);
    backdrop-filter: blur(46px);
    border-radius: 32px;
    color: var(--secondary-elements-text-color); }
  .RaoProductReviews .RaoProductReviews_nav {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.75px; }
    .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-prev, .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-next {
      width: 28px;
      height: 28px;
      border-radius: 14px;
      background-color: var(--button-background);
      padding: 0;
      margin: 0px 5%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-prev > svg, .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-next > svg {
        height: 12px;
        fill: var(--button-text-color); }
    .RaoProductReviews .RaoProductReviews_nav .button_hidden {
      visibility: hidden; }

@media screen and (min-width: 641px) {
  .RaoProductReviews {
    display: block;
    width: 100%;
    padding: 7% 9.5%; }
    .RaoProductReviews .RaoProductReviews_title {
      font-size: 30px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 1px;
      padding-bottom: 4%; }
    .RaoProductReviews .RaoProductReviews_slide {
      display: grid;
      grid-gap: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      padding: 0;
      width: 100%;
      min-width: 100%;
      max-width: 100%; }
      .RaoProductReviews .RaoProductReviews_slide.no-desktop {
        grid-template-columns: minmax(250px, 25%) auto; }
        .RaoProductReviews .RaoProductReviews_slide.no-desktop.hidden-initial {
          display: none; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
        backdrop-filter: blur(46px);
        border-radius: 32px;
        padding: 3.2% 7%;
        display: flex;
        flex-direction: row;
        color: var(--secondary-elements-text-color); }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_header, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_header, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_header {
          width: 30%;
          display: flex;
          align-items: center;
          justify-content: center; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_header .RaoProductReviews_slide_content_user_imageContainer > img, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_header .RaoProductReviews_slide_content_user_imageContainer > img, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_header .RaoProductReviews_slide_content_user_imageContainer > img {
            border-radius: 60px;
            object-fit: cover;
            object-position: center; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content {
          width: 70%;
          padding-left: 8%; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_title {
            font-weight: 600;
            letter-spacing: 1px; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer {
            display: flex; }
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
              font-weight: 600;
              letter-spacing: 1px; }
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
              font-weight: 300;
              letter-spacing: 0.75px;
              margin-left: 10%;
              margin-top: 2%; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 {
        background: rgba(62, 58, 180, 0.5); }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 {
        background: rgba(255, 192, 147, 0.5);
        color: var(--text-color); }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg > path {
          fill: #D6874F; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 {
        background: rgba(77, 204, 200, 0.5); }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c1 {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 1;
        background: rgba(62, 58, 180, 0.5);
        box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
        backdrop-filter: blur(46px);
        border-radius: 32px;
        padding: 2.3% 8%;
        display: flex;
        flex-direction: row;
        color: var(--secondary-elements-text-color); }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c1 .RaoProductReviews_slide_header {
          width: 44%;
          display: flex;
          flex-direction: column;
          justify-content: center; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c1 .RaoProductReviews_slide_content {
          width: 56%; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c2 {
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 2;
        grid-row-end: 2;
        background: rgba(255, 192, 147, 0.5);
        box-shadow: 0px 4px 4px rgba(180, 154, 136, 0.21);
        backdrop-filter: blur(46px);
        border-radius: 32px;
        padding: 8.5% 7%;
        display: flex;
        flex-direction: column; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c2 .RaoProductReviews_slide_header {
          width: 100%;
          padding-left: 7%; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c2 .RaoProductReviews_slide_content {
          width: 100%; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
            padding-left: 7%;
            padding-right: 7%; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg > path {
            fill: #D6874F; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c3 {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 2;
        background: rgba(77, 204, 200, 0.5);
        box-shadow: 0px 4px 4px rgba(129, 139, 136, 0.25);
        backdrop-filter: blur(46px);
        border-radius: 32px;
        padding: 8.5% 7%;
        display: flex;
        flex-direction: column;
        color: var(--secondary-elements-text-color); }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c3 .RaoProductReviews_slide_header {
          width: 100%;
          padding-left: 7%; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c3 .RaoProductReviews_slide_content {
          width: 100%; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
            padding-left: 7%;
            padding-right: 7%; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_title {
        font-weight: 600;
        font-size: 30px;
        line-height: 32px;
        letter-spacing: 1px; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer {
        display: flex;
        align-items: baseline;
        margin-top: 2%; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
          font-weight: 600;
          font-size: 20px;
          line-height: 21px;
          letter-spacing: 1px; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
          font-weight: 200;
          font-size: 12px;
          line-height: 21px;
          letter-spacing: 0.75px;
          margin-left: 3%; }
      .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
          font-weight: 200;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.75px;
          padding: 30px 0; }
        .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user {
          display: flex;
          align-items: center; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_imageContainer {
            width: 100px;
            height: 100px;
            margin: 0;
            margin-right: 2%; }
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_imageContainer > img {
              border-radius: 50%;
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_name {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.44px; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_position {
            font-weight: 200;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.44px;
            padding-bottom: 5%; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg {
            height: 20px;
            width: 20px; }
          .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_review_rating > svg:not(:last-child) {
            margin-right: 5px; }
    .RaoProductReviews .RaoProductReviews_nav {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center; }
      .RaoProductReviews .RaoProductReviews_nav .swiper-pagination {
        position: relative; }
        .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet {
          width: auto;
          height: auto;
          background: none;
          font-weight: 200;
          font-size: 16px;
          line-height: 18px;
          opacity: 1;
          margin: 0 8px; }
        .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet-active {
          font-weight: 600;
          font-size: 22px;
          line-height: 24px; }
      .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-prev, .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-next {
        width: 54px;
        height: 54px;
        border-radius: 26px;
        background-color: var(--button-background);
        padding: 0;
        margin: 0px 1.2%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-prev > svg, .RaoProductReviews .RaoProductReviews_nav .RaoProductReviews_nav-button-next > svg {
          height: 24px;
          fill: var(--button-text-color); }
      .RaoProductReviews .RaoProductReviews_nav .button_hidden {
        visibility: hidden; } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_title {
              font-size: calc(48px * 0.62);
              line-height: calc(50px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_title {
              font-size: calc(48px * 0.62);
              line-height: calc(50px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_title {
              font-size: calc(48px * 0.75);
              line-height: calc(50px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_title {
              font-size: calc(48px * 0.875);
              line-height: calc(50px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_title, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_title {
              font-size: 48px;
              line-height: 50px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
                font-size: calc(32px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
                font-size: calc(32px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
                font-size: 32px;
                line-height: 34px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
                font-size: calc(18px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
                font-size: calc(18px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
                font-size: calc(18px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
                font-size: calc(18px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c1 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c2 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date, .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_alt_c3 .RaoProductReviews_slide_content .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
                font-size: 18px;
                line-height: 34px; } }
@media screen and (min-width: 1440px) {
  .RaoProductReviews .RaoProductReviews_title {
    font-size: 36px;
    line-height: 38px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_title {
    font-size: 36px;
    line-height: 38px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
    font-size: 24px;
    line-height: 26px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
    font-size: 14px;
    line-height: 26px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
    font-size: 18px;
    line-height: 29px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_name {
    font-size: 18px;
    line-height: 27px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_position {
    font-size: 14px;
    line-height: 16px; }
  .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet {
    font-size: 18px;
    line-height: 21px; }
  .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet-active {
    font-size: 27px;
    line-height: 21px; } }
@media screen and (min-width: 1680px) {
  .RaoProductReviews .RaoProductReviews_title {
    font-size: 42px;
    line-height: 43px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_title {
    font-size: 42px;
    line-height: 43px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
    font-size: 28px;
    line-height: 30px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
    font-size: 16px;
    line-height: 30px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
    font-size: 21px;
    line-height: 34px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_name {
    font-size: 21px;
    line-height: 32px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_position {
    font-size: 16px;
    line-height: 18px; }
  .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet {
    font-size: 21px;
    line-height: 25px; }
  .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet-active {
    font-size: 32px;
    line-height: 25px; } }
@media screen and (min-width: 1920px) {
  .RaoProductReviews .RaoProductReviews_title {
    font-size: 48px;
    line-height: 50px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_title {
    font-size: 48px;
    line-height: 50px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_subtitle {
    font-size: 32px;
    line-height: 34px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_header .RaoProductReviews_slide_subtitleContainer .RaoProductReviews_slide_date {
    font-size: 18px;
    line-height: 34px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_text {
    font-size: 24px;
    line-height: 38px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_name {
    font-size: 24px;
    line-height: 36px; }
  .RaoProductReviews .RaoProductReviews_slide .RaoProductReviews_slide_content .RaoProductReviews_slide_content_user .RaoProductReviews_slide_content_user_data .RaoProductReviews_slide_content_user_position {
    font-size: 18px;
    line-height: 20.95px; }
  .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet {
    font-size: 24px;
    line-height: 28px; }
  .RaoProductReviews .RaoProductReviews_nav .swiper-pagination .swiper-pagination-bullet-active {
    font-size: 36px;
    line-height: 28px; } }
/**
 * ----------------------------------------------------------------------------
 * Custom footer styles
 * ----------------------------------------------------------------------------
*/
.Footer {
  border: none;
  padding: 0; }
  .Footer .Container__footer-logo-mobile {
    width: 100%;
    text-align: center;
    padding: 14% 20%; }
    .Footer .Container__footer-logo-mobile > svg {
      width: 100%; }
  .Footer .Container {
    padding: 0 8.5%;
    padding-bottom: 40%; }
    .Footer .Container .Footer__Inner {
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px; }
      .Footer .Container .Footer__Inner .Footer__ShopInfo {
        font-weight: 200;
        font-size: 13px;
        line-height: 21px;
        width: 100%; }
      .Footer .Container .Footer__Inner .Footer__Block {
        margin: 0;
        padding: 0;
        flex-basis: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
        .Footer .Container .Footer__Inner .Footer__Block .Footer__Title {
          font-weight: 600;
          font-size: 18px;
          line-height: 34px;
          letter-spacing: 0.75px; }
        .Footer .Container .Footer__Inner .Footer__Block .Linklist__Item {
          font-weight: 300;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: 0.75px;
          margin: 0; }
      .Footer .Container .Footer__Inner .Footer__Block:nth-child(2) {
        order: 1; }
      .Footer .Container .Footer__Inner .Footer__Block:nth-child(3) {
        order: 3; }
      .Footer .Container .Footer__Inner .Footer__Block:nth-child(4) {
        order: 2; }
      .Footer .Container .Footer__Inner .Footer__Block:nth-child(5) {
        order: 4; }
        .Footer .Container .Footer__Inner .Footer__Block:nth-child(5) .Footer__ShopInfo {
          padding-top: 18%; }

@media screen and (min-width: 641px) {
  .Footer {
    border: none;
    padding: 0; }
    .Footer .Container {
      padding: 6% 10%; }
      .Footer .Container .Container__footer-logo {
        width: 100%;
        text-align: center;
        padding-bottom: 5.5%; }
        .Footer .Container .Container__footer-logo > svg {
          width: auto; }
      .Footer .Container .Footer__Inner {
        display: flex;
        margin: 0;
        padding: 0; }
        .Footer .Container .Footer__Inner .Footer__Block {
          margin: 0;
          padding: 0;
          display: block !important;
          order: initial !important; }
          .Footer .Container .Footer__Inner .Footer__Block .Footer__Title {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.75px; }
        .Footer .Container .Footer__Inner .Footer__Block--links .Linklist__Item {
          font-weight: 300;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.75px;
          margin: 0; }
        .Footer .Container .Footer__Inner .Footer__Block--text {
          flex-basis: 260px; }
        .Footer .Container .Footer__Inner .Footer__ShopInfo {
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.75px;
          width: 18%; } }
@media screen and (min-width: 1440px) {
  .Footer .Container .Footer__Inner .Footer__Block .Footer__Title {
    font-size: 15px;
    line-height: 26px; }
  .Footer .Container .Footer__Inner .Footer__Block--links .Linklist__Item {
    font-size: 14px;
    line-height: 22px; }
  .Footer .Container .Footer__Inner .Footer__ShopInfo {
    font-size: 14px;
    line-height: 22px; } }
@media screen and (min-width: 1680px) {
  .Footer .Container .Footer__Inner .Footer__Block .Footer__Title {
    font-size: 16px;
    line-height: 30px; }
  .Footer .Container .Footer__Inner .Footer__Block--links .Linklist__Item {
    font-size: 15px;
    line-height: 25px; }
  .Footer .Container .Footer__Inner .Footer__ShopInfo {
    font-size: 15px;
    line-height: 25px; } }
@media screen and (min-width: 1920px) {
  .Footer .Container .Footer__Inner .Footer__Block .Footer__Title {
    font-size: 18px;
    line-height: 34px; }
  .Footer .Container .Footer__Inner .Footer__Block--links .Linklist__Item {
    font-size: 16px;
    line-height: 28px; }
  .Footer .Container .Footer__Inner .Footer__ShopInfo {
    font-size: 16px;
    line-height: 28px; } }
/**
 * ----------------------------------------------------------------------------
 * Custom sidebar cart styles
 * ----------------------------------------------------------------------------
*/
#sidebar-cart {
  max-width: 414px;
  width: 100%;
  background: var(--header-background);
  overflow: auto; }
  #sidebar-cart::before {
    height: fit-content; }
  #sidebar-cart .Drawer__Header {
    margin: 25px 25px 0 25px;
    padding: 0;
    width: auto;
    border-bottom: 1px solid var(--border-color); }
    #sidebar-cart .Drawer__Header .Drawer__Title {
      font-size: 32px;
      line-height: 50px;
      letter-spacing: 1px; }
    #sidebar-cart .Drawer__Header .Drawer__Close {
      right: 5px; }
      #sidebar-cart .Drawer__Header .Drawer__Close .Icon {
        width: 18px;
        height: 18px;
        stroke-width: 2.5px; }
  #sidebar-cart .Drawer__Content {
    padding: 28px 25px;
    padding-bottom: 20px;
    overflow: auto;
    height: auto;
    max-height: fit-content; }
    #sidebar-cart .Drawer__Content .Drawer__Main {
      flex: none; }
      #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice {
        padding: 16px 0;
        background: #FBF1EA;
        border-radius: 50px;
        border: none; }
        #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container {
          padding: 0 24px;
          margin: 0;
          height: 100%;
          max-height: 100%;
          display: flex;
          align-items: center; }
          #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Icon {
            height: 47px; }
            #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Icon svg {
              height: 47px;
              width: 53px; }
          #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Content {
            padding: 0 12px;
            width: calc(100% - 70px);
            display: flex;
            flex-direction: column; }
            #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Content .Cart__ShippingNotice_Content_ProgressBarWrapper {
              height: 15px;
              background: #FACEAF;
              border-radius: 13px;
              position: relative;
              z-index: 1; }
              #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Content .Cart__ShippingNotice_Content_ProgressBarWrapper .Cart__ShippingNotice_Content_ProgressBar {
                position: absolute;
                border-radius: 13px;
                top: 0;
                bottom: 0;
                left: 0;
                background-color: var(--button-background);
                z-index: -1;
                width: 30%; }
              #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Content .Cart__ShippingNotice_Content_ProgressBarWrapper .Cart__ShippingNotice_Success {
                background-color: #53b910 !important; }
            #sidebar-cart .Drawer__Content .Drawer__Main .Cart__ShippingNotice .Drawer__Container .Cart__ShippingNotice_Content .Cart__ShippingNotice_Content_Text {
              margin-top: 7px;
              font-size: 15.3463px;
              line-height: 18px;
              letter-spacing: 0.639427px; }
      #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container {
        padding: 0; }
        #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList {
          margin-top: 18px; }
          #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper:last-child {
            border-bottom: 1px solid var(--border-color); }
          #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem {
            position: relative; }
            #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__ImageWrapper {
              width: 90px; }
            #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info {
              width: 100%; }
              #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__Title {
                font-weight: 600;
                font-size: 20.46px;
                line-height: 24px;
                letter-spacing: 0.63px;
                margin-bottom: 0; }
              #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__Meta .CartItem__Variant {
                font-weight: 300;
                font-size: 15.35px;
                line-height: 17.86px;
                letter-spacing: 0.64px; }
              #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 5px; }
                #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions {
                  margin-top: 0;
                  width: 100%;
                  display: block; }
                  #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__QuantitySelector__Title {
                    font-size: 12px;
                    line-height: 26px;
                    letter-spacing: 0.76px;
                    text-align: left; }
                  #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__QuantitySelector {
                    display: flex; }
                    #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__QuantitySelector .QuantitySelector {
                      height: 34px;
                      border: 1px solid var(--button-background);
                      border-radius: 7.6px; }
                      #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__QuantitySelector .QuantitySelector .QuantitySelector__Button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        padding: 0;
                        margin: 0;
                        width: 26px; }
                      #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__QuantitySelector .QuantitySelector .QuantitySelector__CurrentQuantity {
                        height: 100%;
                        width: 42px;
                        border-left: 1px solid var(--button-background);
                        border-right: 1px solid var(--button-background);
                        font-size: 18px;
                        line-height: 26px;
                        letter-spacing: 0.76px; }
                    #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__QuantitySelector .CartItem__PriceList {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 22px;
                      letter-spacing: 0.64px;
                      color: #007F7B; }
                  #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__DiscountList {
                    margin-top: 15px; }
                    #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Info .CartItem__AdditionalData .CartItem__Actions .CartItem__DiscountList .CartItem__Discount {
                      background: rgba(0, 127, 123, 0.1);
                      color: #007F7B; }
            #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Remove {
              position: absolute;
              top: -25px;
              right: 5px;
              outline: none;
              margin: 0; }
              #sidebar-cart .Drawer__Content .Drawer__Main .Drawer__Container .Cart__ItemList .CartItemWrapper .CartItem .CartItem__Remove > svg {
                width: 18px;
                height: 18px;
                stroke-width: 2.5px; }
    #sidebar-cart .Drawer__Content .Cart__Empty {
      top: 12%; }
  #sidebar-cart .Drawer__Footer {
    padding-left: 0;
    padding-right: 0;
    border: none;
    box-shadow: none;
    overflow: visible;
    text-align: right;
    overflow: visible; }
    #sidebar-cart .Drawer__Footer .CartTotal__Discount {
      font-size: 10px;
      background: rgba(0, 127, 123, 0.1);
      color: #007F7B; }
    #sidebar-cart .Drawer__Footer .Cart__GiftMessage {
      text-align: left;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0.64px; }
      #sidebar-cart .Drawer__Footer .Cart__GiftMessage label > input[type="checkbox"] {
        display: none; }
      #sidebar-cart .Drawer__Footer .Cart__GiftMessage label > input[type="checkbox"] + *::before {
        content: "";
        display: inline-block;
        vertical-align: bottom;
        margin-right: 0.3rem;
        border-radius: 3px;
        border-style: solid;
        border-width: 1px;
        border-color: var(--text-color);
        width: 21px;
        height: 21px;
        line-height: 21px;
        margin-right: 7px; }
      #sidebar-cart .Drawer__Footer .Cart__GiftMessage label > input[type="checkbox"]:checked + * {
        color: var(--text-color); }
      #sidebar-cart .Drawer__Footer .Cart__GiftMessage label > input[type="checkbox"]:checked + *::before {
        content: "✓";
        color: white;
        text-align: center;
        background: var(--text-color);
        border-color: var(--text-color); }
      #sidebar-cart .Drawer__Footer .Cart__GiftMessage label > input[type="checkbox"] + * {
        display: inline-block;
        padding: 0.5rem 0; }
    #sidebar-cart .Drawer__Footer .Cart__TotalPrice {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 15px 0; }
      #sidebar-cart .Drawer__Footer .Cart__TotalPrice .Cart__TotalPrice_Label {
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.64px; }
      #sidebar-cart .Drawer__Footer .Cart__TotalPrice .Cart__TotalPrice_Price {
        font-weight: 600;
        font-size: 32px;
        line-height: 22px;
        letter-spacing: 0.64px;
        color: #007F7B; }
        #sidebar-cart .Drawer__Footer .Cart__TotalPrice .Cart__TotalPrice_Price .Cart__TotalPrice_Price_Curent_Price {
          color: #007F7B; }
    #sidebar-cart .Drawer__Footer .Cart__Checkout {
      max-width: 100%;
      height: auto;
      font-size: 16px; }
      #sidebar-cart .Drawer__Footer .Cart__Checkout::after {
        content: "";
        display: block;
        background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy.svg?v=711253759822625200");
        background-size: contain;
        background-repeat: no-repeat;
        width: 24px;
        height: 22px;
        float: left;
        margin-left: 10px; }

/**
 * ----------------------------------------------------------------------------
 * Custom cart recommendations styles
 * ----------------------------------------------------------------------------
*/
.CartRecommendations {
  width: 100%;
  margin-top: 50px; }
  .CartRecommendations .CartRecommendations_Title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.64px;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto; }
  .CartRecommendations .CartRecommendations_ProductsContainer {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
    position: relative; }
    .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item {
      display: flex;
      flex-direction: column; }
      .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item > .AspectRatio {
        width: 100%; }
      .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductsContainer_Item_TextContainer {
        margin-top: 10px; }
        .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductsContainer_Item_TextContainer .CartRecommendations_ProductsContainer_Item_Title {
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.64px; }
        .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductsContainer_Item_TextContainer .CartRecommendations_ProductsContainer_Item_PriceContainer {
          margin-top: 15px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between; }
          .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductsContainer_Item_TextContainer .CartRecommendations_ProductsContainer_Item_PriceContainer .CartRecommendations_ProductsContainer_Item_Price {
            font-weight: 600;
            font-size: 20px;
            line-height: 19px;
            letter-spacing: 0.55px;
            color: #007F7B; }
          .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductsContainer_Item_TextContainer .CartRecommendations_ProductsContainer_Item_PriceContainer .CartRecommendations_ProductsContainer_Item_ComparePrice {
            font-size: 14px;
            line-height: 19px;
            letter-spacing: 0.4px;
            text-decoration-line: line-through;
            opacity: 0.26; }
        .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductsContainer_Item_TextContainer .CartRecommendations_ProductsContainer_Item_PriceContainer_single {
          justify-content: center; }
      .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper {
        margin-top: auto; }
        .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper .CartRecommendations__QuantitySelector {
          display: flex;
          margin: 18px 0; }
          .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper .CartRecommendations__QuantitySelector .QuantitySelector {
            height: 34px;
            border: 1px solid var(--button-background);
            border-radius: 7.6px;
            margin: 0 auto; }
            .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper .CartRecommendations__QuantitySelector .QuantitySelector .QuantitySelector__Button {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              padding: 0;
              margin: 0;
              width: 26px; }
            .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper .CartRecommendations__QuantitySelector .QuantitySelector .QuantitySelector__CurrentQuantity {
              height: 100%;
              width: 42px;
              border-left: 1px solid var(--button-background);
              border-right: 1px solid var(--button-background);
              font-size: 18px;
              line-height: 26px;
              letter-spacing: 0.76px; }
        .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper .QuantitySelector__AddToCart {
          padding: 0;
          max-height: 60px;
          color: var(--text-color); }
          .CartRecommendations .CartRecommendations_ProductsContainer .CartRecommendations_ProductsContainer_Item .CartRecommendations_ProductForm__SubmitButtonWraper .QuantitySelector__AddToCart:hover {
            cursor: pointer; }

/**
 * ----------------------------------------------------------------------------
 * Custom FAQ styles
 * ----------------------------------------------------------------------------
*/
.Custom_faq-section_style {
  padding-top: 80px;
  padding-bottom: 124px;
  margin-bottom: -80px;
  background: rgba(247, 209, 184, 0.18); }

.Custom_FAQ_Section {
  max-width: 1367px;
  margin-top: 0; }
  .Custom_FAQ_Section .Faq .Faq__Section {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.48px;
    padding-bottom: 10%; }
  .Custom_FAQ_Section .Faq .Faq__Item {
    border-bottom: 1px solid var(--text-color); }
    .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper {
      padding: 20px 0; }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question {
        text-align: center; }
        .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question .Faq__Question_text {
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.48px; }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__AnswerWrapper .Faq__Answer {
        font-size: 14px; }
    .Custom_FAQ_Section .Faq .Faq__Item .Faq__Logo_Icon {
      display: block; }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__Logo_Icon > svg {
        height: 30px; }
    .Custom_FAQ_Section .Faq .Faq__Item .Faq__Icon {
      display: none; }

@media screen and (min-width: 641px) {
  .Custom_faq-section_style {
    padding-top: 80px;
    padding-bottom: 124px;
    margin-bottom: -120px;
    background: rgba(247, 209, 184, 0.18); }

  .Custom_FAQ_Section {
    max-width: 1367px;
    margin: 35px auto; }
    .Custom_FAQ_Section .Faq .Faq__Section {
      font-weight: 600;
      font-size: 48px;
      line-height: 50px;
      text-align: center;
      letter-spacing: 1px;
      padding-bottom: 0; }
    .Custom_FAQ_Section .Faq .Faq__Item {
      border-bottom: 1px solid var(--text-color); }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper {
        padding: 20px 0; }
        .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question {
          display: flex;
          align-items: center;
          text-align: left; }
          .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question .Faq__Question_text {
            padding-left: 40px;
            width: 100%;
            position: relative;
            font-size: 24px;
            line-height: 43px;
            letter-spacing: 1px; }
        .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__AnswerWrapper {
          padding-left: 112px;
          padding-right: 35px; }
          .Custom_FAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__AnswerWrapper .Faq__Answer {
            font-size: 18px; }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__Logo_Icon {
        display: block;
        position: relative; }
        .Custom_FAQ_Section .Faq .Faq__Item .Faq__Logo_Icon > svg {
          height: 50px;
          widht: auto; }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__Icon {
        display: block;
        left: auto;
        right: 0; }
      .Custom_FAQ_Section .Faq .Faq__Item .Faq__Icon::after {
        content: '+';
        font-weight: 600;
        font-size: 54px;
        line-height: 43px;
        letter-spacing: 1px; }
    .Custom_FAQ_Section .Faq .Faq__Item[aria-expanded=true] .Faq__Icon {
      transform: none; }
    .Custom_FAQ_Section .Faq .Faq__Item[aria-expanded=true] .Faq__Icon::after {
      content: '-'; } }
/**
 * ----------------------------------------------------------------------------
 * Custom blog template styles
 * ----------------------------------------------------------------------------
*/
#section-rao-blog-template {
  padding-top: 59px;
  overflow: hidden; }
  #section-rao-blog-template .Blog .Container {
    padding: 0 9.5%; }
    #section-rao-blog-template .Blog .Container .PageHeader {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0; }
      #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_ImageWrapper {
        width: 127%;
        margin-left: 10%;
        margin-bottom: -20%; }
        #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image {
          width: 100%; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image img {
            object-fit: cover;
            object-position: center; }
      #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info {
        width: 100%;
        margin: 0; }
        #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper {
          text-align: left;
          padding-top: 15%;
          padding-bottom: 10%; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: 24px;
            line-height: 50px;
            margin-bottom: 7%; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
            max-width: 100%;
            font-weight: 200;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 1px; }
        #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding-bottom: 40px; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
            font-weight: 500;
            letter-spacing: 1px;
            font-size: 18px;
            line-height: 34px;
            padding-bottom: 14px; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList {
            height: 64px;
            border: 1px solid var(--button-background);
            box-sizing: border-box;
            border-radius: 58px;
            appearance: none;
            padding: 0 34px;
            margin: 0;
            min-width: 100%;
            max-width: 100%; }
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList:required:invalid {
              font-weight: 200;
              font-size: 16px;
              line-height: 28px;
              letter-spacing: 0.75px;
              color: #828282; }
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList option[value=""][disabled] {
              display: none; }
    #section-rao-blog-template .Blog .Container .ArticleListWrapper {
      margin-top: 5.5%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      #section-rao-blog-template .Blog .Container .ArticleListWrapper .ArticleList {
        width: calc(100% + 30px); }

#section-rao-blog-template-optimised .Blog__Optimised .Container {
  padding: 0 10%; }
  #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 5.5%; }
    @media screen and (max-width: 640px) {
      #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader {
        flex-direction: column; }
        #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_ImageWrapper {
          width: 100% !important; }
        #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info {
          width: 100% !important; }
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper {
            padding: 40px 0 0 0 !important; }
            #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: 30px;
              line-height: 32px; } }
    #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_ImageWrapper {
      width: 46%;
      margin: 0; }
      #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image {
        width: 100%; }
        #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image img {
          object-fit: cover;
          object-position: center; }
    #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info {
      width: 54%; }
      #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper {
        text-align: left;
        padding-top: 15%;
        padding-bottom: 10%; }
        @media screen and (min-width: 641px) {
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 1140px) {
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 1440px) {
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.75);
            line-height: calc(50px * 0.75); } }
        @media screen and (min-width: 1680px) {
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.875);
            line-height: calc(50px * 0.875); } }
        @media screen and (min-width: 1920px) {
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: 48px;
            line-height: 50px; } }
        #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
          max-width: 100%;
          font-weight: 200;
          letter-spacing: 1px; }
          @media screen and (min-width: 641px) {
            #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.62);
              line-height: calc(43px * 0.62); } }
          @media screen and (min-width: 1140px) {
            #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.62);
              line-height: calc(43px * 0.62); } }
          @media screen and (min-width: 1440px) {
            #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.75);
              line-height: calc(43px * 0.75); } }
          @media screen and (min-width: 1680px) {
            #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.875);
              line-height: calc(43px * 0.875); } }
          @media screen and (min-width: 1920px) {
            #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: 32px;
              line-height: 43px; } }
      #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .Blog__filter {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding-bottom: 40px; }
        #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
          font-weight: 500;
          letter-spacing: 1px;
          font-size: 18px;
          line-height: 34px;
          padding-bottom: 14px; }
        #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList {
          height: 64px;
          border: 1px solid var(--button-background);
          box-sizing: border-box;
          border-radius: 58px;
          appearance: none;
          padding: 0 34px;
          margin: 0;
          min-width: 100%;
          max-width: 100%; }
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList:required:invalid {
            font-weight: 200;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.75px;
            color: #828282; }
          #section-rao-blog-template-optimised .Blog__Optimised .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList option[value=""][disabled] {
            display: none; }
#section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper {
  margin: 0; }
  @media screen and (min-width: 1140px) {
    #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper {
      width: calc(100% - 360px);
      float: left; } }
  @media screen and (min-width: 1008px) and (max-width: 1139px) {
    #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper {
      width: calc(100% - 270px);
      float: left; } }
  @media screen and (max-width: 1007px) {
    #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper {
      width: 100%; } }
  #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper .RaoArticleItem2_block .RaoArticleItem2_block_title {
    margin-top: calc(-7% - 5px);
    padding-top: calc(7% + 5px);
    height: auto; }
  #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper .RaoArticleItem2_block .RaoArticleItem2_block_meta {
    margin: 5px 0; }
    #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper .RaoArticleItem2_block .RaoArticleItem2_block_meta span {
      font-size: 15px; }
  #section-rao-blog-template-optimised .Blog__Optimised .ArticleListWrapper .RaoArticleItem2_block .RaoArticleItem2_block_excerpt span {
    font-size: 18px; }
#section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation {
  margin-bottom: 173px;
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(112, 109, 145, 0.1); }
  @media screen and (min-width: 1140px) {
    #section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation {
      float: right;
      position: sticky;
      width: 300px;
      top: 100px; } }
  @media screen and (min-width: 1008px) and (max-width: 1139px) {
    #section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation {
      float: right;
      position: sticky;
      width: 240px;
      top: 100px; } }
  @media screen and (max-width: 1007px) {
    #section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation {
      position: relative;
      top: 60px;
      width: 100%; } }
  #section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation .Blog__Sticky_Navigation--header {
    font-size: 32px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  #section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation .Blog__Sticky_Navigation--link {
    width: 100%;
    display: block;
    padding-top: 10px;
    font-size: 18px; }
    #section-rao-blog-template-optimised .Blog__Optimised .Blog__Sticky_Navigation .Blog__Sticky_Navigation--link:last-child {
      margin-bottom: 50px; }
@media screen and (min-width: 1008px) {
  #section-rao-blog-template-optimised .Blog__Optimised .Pagination {
    clear: both;
    padding-top: 120px; } }

@media screen and (min-width: 641px) {
  #section-rao-blog-template {
    padding-top: calc(40px + 3.2%); }
    #section-rao-blog-template .Blog .Container {
      padding: 0 9.5%; }
      #section-rao-blog-template .Blog .Container .PageHeader {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 0; }
        #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_ImageWrapper {
          width: 46%;
          margin: 0; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image {
            width: 100%; }
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image img {
              object-fit: cover;
              object-position: center; }
        #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info {
          width: 54%;
          margin: 0; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper {
            text-align: left;
            padding-top: 15%;
            padding-bottom: 10%; }
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              max-width: 100%;
              font-weight: 200;
              letter-spacing: 1px; }
          #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-direction: row;
            padding-bottom: 0; }
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
              font-weight: 500;
              letter-spacing: 1px;
              text-align: right;
              padding-right: 30px;
              padding-bottom: 0; }
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__TagList {
              height: 64px;
              border: 1px solid var(--button-background);
              box-sizing: border-box;
              border-radius: 58px;
              appearance: none;
              padding: 0 34px;
              margin: 0;
              max-width: 44%;
              min-width: 44%; }
      #section-rao-blog-template .Blog .Container .ArticleListWrapper {
        margin-top: 5.5%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        #section-rao-blog-template .Blog .Container .ArticleListWrapper .ArticleList {
          width: calc(100% + 30px); } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: calc(48px * 0.62);
              line-height: calc(50px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: calc(48px * 0.62);
              line-height: calc(50px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: calc(48px * 0.75);
              line-height: calc(50px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: calc(48px * 0.875);
              line-height: calc(50px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: 48px;
              line-height: 50px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
                font-size: calc(32px * 0.62);
                line-height: calc(43px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
                font-size: calc(32px * 0.62);
                line-height: calc(43px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
                font-size: calc(32px * 0.75);
                line-height: calc(43px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
                font-size: calc(32px * 0.875);
                line-height: calc(43px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
                font-size: 32px;
                line-height: 43px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
                font-size: calc(18px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
                font-size: calc(18px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
                font-size: calc(18px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
                font-size: calc(18px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              #section-rao-blog-template .Blog .Container .PageHeader .PageHeader_Info .Blog__filter .Blog__filterDescription {
                font-size: 18px;
                line-height: 34px; } }
@media screen and (min-width: 1140px) {
  #section-rao-blog-template .Blog .Container {
    padding: 0 9.5%; }
    #section-rao-blog-template .Blog .Container .PageHeader {
      margin: 0; }
    #section-rao-blog-template .Blog .Container .ArticleListWrapper .ArticleList {
      width: calc(100% + 60px); } }
/**
 * ----------------------------------------------------------------------------
 * Custom rao-article-item styles
 * ----------------------------------------------------------------------------
*/
.RaoArticleItem_block:not(:first-child) {
  margin-top: 8%; }

.RaoArticleItem_block {
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(112, 109, 145, 0.1);
  border-radius: 32px;
  width: 100%; }
  .RaoArticleItem_block .RaoArticleItem_blocks_imageWrapper .RaoArticleItem_blocks_image {
    width: 100%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    object-fit: cover;
    object-position: center; }
  .RaoArticleItem_block .RaoArticleItem_block_content {
    padding: 7%;
    color: var(--text-color); }
    .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
      font-weight: 200;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0.64px;
      text-transform: uppercase; }
    .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: 0.84106px;
      height: 3em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
    .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper {
      padding-top: 6%; }
      .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.84106px;
        color: var(--button-background); }

@media screen and (min-width: 641px) {
  .RaoArticleItem_block:not(:first-child) {
    margin-top: 0; }

  .RaoArticleItem_block {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(112, 109, 145, 0.1);
    border-radius: 32px;
    width: 100%; }
    .RaoArticleItem_block .RaoArticleItem_blocks_imageWrapper .RaoArticleItem_blocks_image {
      width: 100%;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      object-fit: cover;
      object-position: center; }
    .RaoArticleItem_block .RaoArticleItem_block_content {
      padding: 7%;
      color: var(--text-color); }
      .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
        font-weight: 200;
        letter-spacing: 0.75px;
        min-height: 1.95em; }
      .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
        font-weight: 600;
        letter-spacing: 1px; }
      .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper {
        padding-top: 6%; }
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
          font-weight: 600;
          letter-spacing: 1px;
          color: var(--button-background); } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
          font-size: calc(18px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
          font-size: calc(18px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
          font-size: calc(18px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
          font-size: calc(18px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_subTitle {
          font-size: 18px;
          line-height: 34px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
          font-size: calc(24px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
          font-size: calc(24px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_title {
          font-size: 24px;
          line-height: 34px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
            font-size: calc(24px * 0.62);
            line-height: calc(34px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
            font-size: calc(24px * 0.62);
            line-height: calc(34px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
            font-size: calc(24px * 0.75);
            line-height: calc(34px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
            font-size: calc(24px * 0.875);
            line-height: calc(34px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .RaoArticleItem_block .RaoArticleItem_block_content .RaoArticleItem_block_buttonWrapper .RaoArticleItem_block_button {
            font-size: 24px;
            line-height: 34px; } }

/**
 * ----------------------------------------------------------------------------
 * Custom rao-article-item2 styles
 * ----------------------------------------------------------------------------
*/
.RaoArticleItem2_block:not(:first-child) {
  margin-top: 8%; }

.RaoArticleItem2_block {
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(112, 109, 145, 0.1);
  border-radius: 32px;
  width: 100%; }
  .RaoArticleItem2_block .RaoArticleItem2_blocks_imageWrapper .RaoArticleItem2_blocks_image {
    width: 100%;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    object-fit: cover;
    object-position: center; }
  .RaoArticleItem2_block .RaoArticleItem2_block_content {
    padding: 7%;
    color: var(--text-color); }
    .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;
      letter-spacing: 0.84106px;
      height: 3em;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
    .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
      font-weight: 200;
      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0.64px; }
    .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper {
      padding-top: 6%; }
      .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        letter-spacing: 0.84106px;
        color: #007F7B; }

@media screen and (min-width: 641px) {
  .RaoArticleItem2_block:not(:first-child) {
    margin-top: 0; }

  .RaoArticleItem2_block {
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(112, 109, 145, 0.1);
    border-radius: 32px;
    width: 100%; }
    .RaoArticleItem2_block .RaoArticleItem2_blocks_imageWrapper .RaoArticleItem2_blocks_image {
      width: 100%;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      object-fit: cover;
      object-position: center; }
    .RaoArticleItem2_block .RaoArticleItem2_block_content {
      padding: 7%;
      color: var(--text-color); }
      .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
        font-weight: 600;
        letter-spacing: 1px; }
      .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
        font-weight: 300;
        letter-spacing: 1px;
        min-height: 1.95em; }
      .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper {
        padding-top: 6%; }
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
          font-weight: 600;
          letter-spacing: 1px;
          color: #007F7B; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
          font-size: calc(32px * 0.62);
          line-height: calc(48px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
          font-size: calc(32px * 0.62);
          line-height: calc(48px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
          font-size: calc(32px * 0.75);
          line-height: calc(48px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
          font-size: calc(32px * 0.875);
          line-height: calc(48px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_title {
          font-size: 32px;
          line-height: 48px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
          font-size: calc(24px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
          font-size: calc(24px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_subTitle {
          font-size: 24px;
          line-height: 34px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
            font-size: calc(24px * 0.62);
            line-height: calc(34px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
            font-size: calc(24px * 0.62);
            line-height: calc(34px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
            font-size: calc(24px * 0.75);
            line-height: calc(34px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
            font-size: calc(24px * 0.875);
            line-height: calc(34px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .RaoArticleItem2_block .RaoArticleItem2_block_content .RaoArticleItem2_block_buttonWrapper .RaoArticleItem2_block_button {
            font-size: 24px;
            line-height: 34px; } }

/**
 * ----------------------------------------------------------------------------
 * Custom pagination styles
 * ----------------------------------------------------------------------------
*/
.Pagination .Pagination__Nav {
  display: flex;
  align-items: center;
  justify-content: center; }
  .Pagination .Pagination__Nav .Pagination__Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 27px;
    background-color: var(--button-background);
    padding: 0;
    margin: 0; }
    .Pagination .Pagination__Nav .Pagination__Button > svg {
      height: 14px;
      width: auto;
      color: white; }
  .Pagination .Pagination__Nav .Pagination__Pages {
    margin: 0 20px; }
    .Pagination .Pagination__Nav .Pagination__Pages .Pagination__NavItem {
      border: none;
      box-shadow: none;
      font-size: 24px;
      line-height: 28px;
      padding: 5px;
      font-weight: 300; }
    .Pagination .Pagination__Nav .Pagination__Pages .is-active {
      font-weight: 600;
      font-size: 36px; }

/**
 * ----------------------------------------------------------------------------
 *  Blog additional info section styles
 * ----------------------------------------------------------------------------
*/
.BlogAddInfoSection {
  padding: 0 7%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .BlogAddInfoSection .BlogAddInfoSection_ImageWraper {
    width: 100%;
    transform: rotate(45deg); }
  .BlogAddInfoSection .BlogAddInfoSection_ContentWraper {
    width: 100%; }
    .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
      margin-bottom: 5%;
      font-size: 24px;
      line-height: 50px;
      text-align: center; }
    .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
      font-weight: 200;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: center;
      margin-bottom: 15%; }

@media screen and (min-width: 641px) {
  .BlogAddInfoSection {
    padding: 0 7%;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .BlogAddInfoSection .BlogAddInfoSection_ImageWraper {
      width: 44%;
      transform: rotate(0deg); }
    .BlogAddInfoSection .BlogAddInfoSection_ContentWraper {
      width: 56%; }
      .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
        margin-bottom: 5%;
        text-align: left; }
      .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
        font-weight: 200;
        text-align: left;
        letter-spacing: 1px;
        margin-bottom: 0; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentTitle {
          font-size: 48px;
          line-height: 50px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
          font-size: calc(32px * 0.62);
          line-height: calc(43px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
          font-size: calc(32px * 0.62);
          line-height: calc(43px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
          font-size: calc(32px * 0.75);
          line-height: calc(43px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
          font-size: calc(32px * 0.875);
          line-height: calc(43px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .BlogAddInfoSection .BlogAddInfoSection_ContentWraper .BlogAddInfoSection_ContentText {
          font-size: 32px;
          line-height: 43px; } }

/**
 * ----------------------------------------------------------------------------
 *  RAO Slideshow
 * ----------------------------------------------------------------------------
*/
.RaoSlideshow__Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center; }
  .RaoSlideshow__Footer::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }

.RaoSlideshow .RaoSlideshow__Content {
  padding-right: 0%;
  padding-left: 5%;
  width: 90%; }
  .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 1px; }
  .RaoSlideshow .RaoSlideshow__Content .RaoSlideshow__ButtonContainer > .Button {
    width: 200px; }

@media screen and (min-width: 641px) {
  .RaoSlideshow__Footer {
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between; }

  .RaoSlideshow .RaoSlideshow__Content {
    padding-right: 50%; }
    .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 1px; } }
@media screen and (min-width: 641px) and (max-width: 1199px) {
  .RaoSlideshow__Footer {
    width: 70%; }

  .RaoSlideshow .RaoSlideshow__Content {
    padding-right: 50%;
    padding-left: 5%; }
    .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
      font-size: 32px; } }
@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .RaoSlideshow__Footer {
    width: 60%; }

  .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
    font-size: 40px;
    line-height: 41px; } }
@media screen and (min-width: 1440px) and (max-width: 1679px) {
  .RaoSlideshow__Footer {
    width: 50%; }

  .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
    font-size: 48px;
    line-height: 50px; } }
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .RaoSlideshow__Footer {
    width: 50%; }

  .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
    font-size: 56px;
    line-height: 58px; } }
@media screen and (min-width: 1920px) {
  .RaoSlideshow__Footer {
    width: 50%; }

  .RaoSlideshow .RaoSlideshow__Content h1.RaoSlideshow__ContentTitle.SectionHeader__Heading.Heading.u-h1 {
    font-size: 64px;
    line-height: 66px; } }
/**
 * ----------------------------------------------------------------------------
 * Custom RAO Product text section styles
 * ----------------------------------------------------------------------------
*/
.RaoProductTextSection {
  padding: 12% 6%;
  padding-bottom: 0; }
  .RaoProductTextSection .RaoProductTextSection__blockWrapper {
    display: flex;
    flex-wrap: wrap; }
  .RaoProductTextSection .RaoProductTextSection__block {
    margin-bottom: 20%; }
    .RaoProductTextSection .RaoProductTextSection__block:last-child {
      margin-bottom: 0%; }
    .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
      color: var(--button-background);
      font-weight: 500;
      font-size: 22px;
      line-height: 50px;
      margin-bottom: 1em; }
    .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.75px; }
      .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content > h1, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h2, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h3, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h4, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h5, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h6 {
        font-weight: 600;
        color: var(--button-background);
        text-transform: uppercase;
        margin: 0; }
      .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content ul {
        list-style-type: none !important;
        padding-left: 1em;
        margin: 0;
        padding: 0; }
      .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content li {
        padding: 0;
        margin-bottom: 7px; }
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content li:before {
          content: '';
          display: inline-block;
          height: 1em;
          width: 1em;
          background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/background_frame.svg?v=864079558124847358");
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0.5em; }
  .RaoProductTextSection .RaoProductTextSection__block::nth-child(1) {
    margin: 0; }

@media screen and (min-width: 641px) {
  .RaoProductTextSection {
    padding: 7% 9.5%; }
    .RaoProductTextSection .RaoProductTextSection__blockWrapper {
      display: flex;
      flex-wrap: wrap; }
    .RaoProductTextSection .RaoProductTextSection__block {
      margin-bottom: 0;
      margin-top: 5%; }
      .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
        color: var(--button-background);
        font-weight: 600;
        margin-bottom: 0.5em; }
      .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
        letter-spacing: 0.75px; }
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content > h1, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h2, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h3, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h4, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h5, .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content h6 {
          font-weight: 600;
          color: var(--button-background);
          text-transform: uppercase;
          margin: 0; }
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content ul {
          list-style-type: none !important;
          padding-left: 1em;
          margin: 0;
          padding: 0; }
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content li {
          padding: 0; }
          .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content li:before {
            content: '';
            display: inline-block;
            height: 1em;
            width: 1em;
            background-image: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/background_frame.svg?v=864079558124847358");
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 0.5em; }
    .RaoProductTextSection .RaoProductTextSection__block:nth-child(1), .RaoProductTextSection .RaoProductTextSection__block:nth-child(2) {
      width: 50%;
      margin: 0; }
    .RaoProductTextSection .RaoProductTextSection__block:nth-child(1) {
      padding-right: 6%; }
    .RaoProductTextSection .RaoProductTextSection__block:nth-child(2) {
      padding-left: 6%; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__title {
          font-size: 48px;
          line-height: 50px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
          font-size: calc(24px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
          font-size: calc(24px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoProductTextSection .RaoProductTextSection__block .RaoProductTextSection__block__content {
          font-size: 24px;
          line-height: 34px; } }
/**
 * ----------------------------------------------------------------------------
 * Custom RAO Product Text With Img Section styles
 * ----------------------------------------------------------------------------
*/
.RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper {
  padding: 21% 4%; }
  .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block {
    padding: 10% 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; }
    .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_image {
      width: 100%; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_image > div > img {
        object-fit: contain;
        object-position: top;
        border-radius: 21px; }
    .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
      width: 100%;
      font-weight: 200;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.53px; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.7px;
        margin: 0;
        margin-top: 10%; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text .ProductForm__AddToCart {
        margin-top: 8%;
        height: 64px;
        max-width: 100%; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text .Button--primary::after {
        content: "";
        display: block;
        background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy.svg?v=711253759822625200");
        background-size: contain;
        background-repeat: no-repeat;
        width: 24px;
        height: 22px;
        float: left;
        margin-left: 10px; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text .Button--secondary::after {
        content: "";
        display: block;
        background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy_secondary.svg?v=12974676526321600639");
        background-size: contain;
        background-repeat: no-repeat;
        width: 24px;
        height: 22px;
        float: left;
        margin-left: 10px; }
    .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block:first-child {
      padding-top: 0; }
    .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block:last-child {
      padding-bottom: 0; }

@media screen and (min-width: 641px) {
  .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper {
    padding: 9.5%; }
    .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block {
      padding: 10% 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_image {
        width: 42%;
        border-radius: 32px; }
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_image > img {
          width: 100%;
          height: auto; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
        width: 52.8%;
        font-weight: 300;
        letter-spacing: 0.75px; }
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
          font-weight: 300;
          letter-spacing: 1px;
          margin: 0; }
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text .ProductForm__AddToCart {
          margin-top: 5%;
          max-width: 260px; }
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text .Button--primary::after {
          content: "";
          display: block;
          background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy.svg?v=711253759822625200");
          background-size: contain;
          background-repeat: no-repeat;
          width: 24px;
          height: 22px;
          float: left;
          margin-left: 10px; }
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text .Button--secondary::after {
          content: "";
          display: block;
          background: url("//cdn.shopify.com/s/files/1/0012/9816/9943/t/40/assets/buy_secondary.svg?v=12974676526321600639");
          background-size: contain;
          background-repeat: no-repeat;
          width: 24px;
          height: 22px;
          float: left;
          margin-left: 10px; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block:nth-child(2n) {
        flex-direction: row-reverse; }
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block:nth-child(2n) .RaoProductTextWithImgSection__block_text {
          width: 50.8%; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block:first-child {
        padding-top: 0; }
      .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block:last-child {
        padding-bottom: 0; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
          font-size: calc(24px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
          font-size: calc(24px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
          font-size: calc(24px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text {
          font-size: 24px;
          line-height: 34px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
            font-size: calc(48px * 0.62);
            line-height: calc(58px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
            font-size: calc(48px * 0.62);
            line-height: calc(58px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
            font-size: calc(48px * 0.75);
            line-height: calc(58px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
            font-size: calc(48px * 0.875);
            line-height: calc(58px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text > h1, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h2, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h3, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h4, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h5, .RaoProductTextWithImgSection .RaoProductTextWithImgSection__blockWrapper .RaoProductTextWithImgSection__block .RaoProductTextWithImgSection__block_text h6 {
            font-size: 48px;
            line-height: 58px; } }
/**
 * ----------------------------------------------------------------------------
 * Custom RAO Collection page styles
 * ----------------------------------------------------------------------------
*/
.RaoPageHeader {
  margin: 0;
  margin-top: var(--header-height);
  padding: 10% 0; }
  .RaoPageHeader .Container {
    padding: 0 4.1%; }
  .RaoPageHeader .SectionHeader__Heading {
    font-weight: 300;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 1px; }
  .RaoPageHeader .SectionHeader__Description {
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    max-width: 90%; }

@media screen and (min-width: 641px) {
  .RaoPageHeader {
    margin: 0;
    margin-top: var(--header-height);
    padding: 5% 0; }
    .RaoPageHeader .SectionHeader__Heading {
      font-weight: 300;
      letter-spacing: 1px; }
    .RaoPageHeader .SectionHeader__Description {
      font-weight: 200;
      max-width: 45%; } }
    @media screen and (min-width: 641px) and (min-width: 641px) {
      .RaoPageHeader .SectionHeader__Heading {
        font-size: calc(48px * 0.62);
        line-height: calc(58px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1140px) {
      .RaoPageHeader .SectionHeader__Heading {
        font-size: calc(48px * 0.62);
        line-height: calc(58px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1440px) {
      .RaoPageHeader .SectionHeader__Heading {
        font-size: calc(48px * 0.75);
        line-height: calc(58px * 0.75); } }
    @media screen and (min-width: 641px) and (min-width: 1680px) {
      .RaoPageHeader .SectionHeader__Heading {
        font-size: calc(48px * 0.875);
        line-height: calc(58px * 0.875); } }
    @media screen and (min-width: 641px) and (min-width: 1920px) {
      .RaoPageHeader .SectionHeader__Heading {
        font-size: 48px;
        line-height: 58px; } }
    @media screen and (min-width: 641px) and (min-width: 641px) {
      .RaoPageHeader .SectionHeader__Description {
        font-size: calc(32px * 0.62);
        line-height: calc(43px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1140px) {
      .RaoPageHeader .SectionHeader__Description {
        font-size: calc(32px * 0.62);
        line-height: calc(43px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1440px) {
      .RaoPageHeader .SectionHeader__Description {
        font-size: calc(32px * 0.75);
        line-height: calc(43px * 0.75); } }
    @media screen and (min-width: 641px) and (min-width: 1680px) {
      .RaoPageHeader .SectionHeader__Description {
        font-size: calc(32px * 0.875);
        line-height: calc(43px * 0.875); } }
    @media screen and (min-width: 641px) and (min-width: 1920px) {
      .RaoPageHeader .SectionHeader__Description {
        font-size: 32px;
        line-height: 43px; } }

/**
 * ----------------------------------------------------------------------------
 * Custom RAO Collection products list styles
 * ----------------------------------------------------------------------------
*/
.RaoCollectionProductList {
  display: flex;
  flex-direction: column;
  /*   .RaoProductItem:nth-child(3n-2) {
      background: linear-gradient(269.63deg, #85D3D1 13.98%, #B9E8E6 89.06%);
      color: #FFFFFF;
  
      .RaoProductItem__Wrapper {
        .RaoProductItem__details {
          .RaoProductItem__details_textBlock {
            .RaoProductItem__details_textBlock_buttonsWrapper {
              .RaoProductItem__details_textBlock_button__AddToCart {
                color: #A9E2E0;
                border-color: #ffffff;
  
                &::before {
                  background: #ffffff;
                }
                & > svg {
                 fill: #A9E2E0;
                }
              }
  
  
              .RaoProductItem__details_textBlock_button__view {
                background-color: transparent;
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
              }
            }
          }
        }
      }
    }
    .RaoProductItem:nth-child(3n-1) {
      background: linear-gradient(89.67deg, #9290D1 8.74%, #BEBCE8 80.92%);
      color: #FFFFFF;
  
      .RaoProductItem__Wrapper {
        .RaoProductItem__details {
          .RaoProductItem__details_textBlock {
            .RaoProductItem__details_textBlock_priceWrapper {
              .RaoProductItem__details_textBlock_price {
                color: var(--text-color);
              }
            }
  
            .RaoProductItem__details_textBlock_buttonsWrapper {
              .RaoProductItem__details_textBlock_button__AddToCart {
                border-color: #ffffff;
                color: var(--text-color);
  
                &::before {
                  background: #ffffff;
                }
                & > svg {
                 fill: var(--text-color);
                }
              }
  
  
              .RaoProductItem__details_textBlock_button__view {
                background-color: transparent;
                color: #FFFFFF;
                border: 2px solid #FFFFFF;
              }
            }
          }
        }
      }
    }
    
    .RaoProductItem:nth-child(3n) {
      background: linear-gradient(272.66deg, #FBF0E9 22.5%, rgba(251, 240, 233, 0) 92.79%);
  
      .RaoProductItem__Wrapper {
        .RaoProductItem__details {
          .RaoProductItem__details_textBlock {
            .RaoProductItem__details_textBlock_buttonsWrapper {
              .RaoProductItem__details_textBlock_button__AddToCart {
                & > svg {
                 fill: #ffffff;
                }
              }
  
  
              .RaoProductItem__details_textBlock_button__view {
                background-color: transparent;
                color: var(--text-color);
                border: 2px solid var(--text-color);
              }
            }
          }
        }
      }
    } */ }
  .RaoCollectionProductList .RaoProductItem {
    width: 100%;
    padding: 20% 4.1%; }
    .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper {
      display: flex;
      flex-direction: column; }
      .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_image {
          width: 100%;
          padding: 0 5.9%; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_image .ProductItem__Image {
            object-fit: cover;
            object-position: center;
            border-radius: 18px; }
        .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock {
          width: 100%;
          margin-top: 10%; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
            font-weight: 700;
            font-size: 27px;
            line-height: 32px;
            max-width: 100%;
            text-align: start;
            letter-spacing: 0.84px;
            padding: 0 3%; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
              font-size: 21px;
              line-height: 24px;
              font-weight: 300;
              letter-spacing: 0.84px; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper {
            display: flex;
            margin: 4% 0;
            padding: 0 3%; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
              font-weight: 600;
              font-size: 26px;
              line-height: 29px;
              letter-spacing: 0.84px; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
              font-weight: 300;
              font-size: 16px;
              line-height: 29px;
              letter-spacing: 0.75px;
              text-decoration-line: line-through;
              margin-left: 5px; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.75px; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper {
            display: flex;
            flex-direction: column;
            align-items: center; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper > div:first-child {
              margin-top: 20%; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper > .Button {
              height: 62px;
              width: 100%;
              max-width: 100%; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__AddToCart {
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
              margin-top: 10.5%; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__AddToCart > svg {
                margin-left: 10px; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__view {
              margin-top: 8%;
              font-weight: 600;
              font-size: 16px;
              line-height: 28px; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__view:hover {
                cursor: pointer; }
      .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__review > div:first-child {
        margin-top: 19%; }
  .RaoCollectionProductList .SingleProductReview_link {
    margin-bottom: -10%; }

@media screen and (min-width: 641px) {
  .RaoCollectionProductList {
    display: flex;
    flex-direction: column; }
    .RaoCollectionProductList .RaoProductItem {
      width: 100%;
      padding: 5.4% 9.2%; }
      .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper {
        display: flex;
        flex-direction: column; }
        .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_image {
            width: 35%;
            padding: 0; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_image .ProductItem__Image {
              object-fit: cover;
              object-position: center;
              border-radius: 60px; }
          .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock {
            width: 50%;
            margin-top: 0; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
              padding: 0;
              font-weight: 700;
              text-align: left;
              letter-spacing: 1px; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
                font-weight: 300;
                letter-spacing: 1px; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper {
              display: flex;
              margin-bottom: 4%;
              margin-top: 6%;
              padding: 0; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
                font-weight: 700;
                letter-spacing: 1px; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
                font-weight: 200;
                letter-spacing: 0.75px;
                text-decoration-line: line-through;
                margin-left: 10px; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
              font-weight: 300;
              letter-spacing: 0.75px; }
            .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper {
              display: flex;
              flex-direction: row;
              margin-top: 8%; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__AddToCart {
                max-width: 260px;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px;
                margin-top: 0; }
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__AddToCart > svg {
                  margin-left: 10px; }
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__view {
                max-width: 260px;
                margin-left: 11%;
                margin-top: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 28px; }
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_buttonsWrapper .RaoProductItem__details_textBlock_button__view:hover {
                  cursor: pointer; }
        .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__review > div:first-child {
          margin-top: 5%; }
    .RaoCollectionProductList .RaoProductItem:nth-child(2n) .RaoProductItem__Wrapper .RaoProductItem__details {
      flex-direction: row-reverse; }
    .RaoCollectionProductList .SingleProductReview_link {
      margin-bottom: -2%; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
                font-size: calc(32px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
                font-size: calc(32px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title {
                font-size: 32px;
                line-height: 34px; } }
              @media screen and (min-width: 641px) and (min-width: 641px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
                  font-size: calc(32px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1140px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
                  font-size: calc(32px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1440px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
                  font-size: calc(32px * 0.75);
                  line-height: calc(34px * 0.75); } }
              @media screen and (min-width: 641px) and (min-width: 1680px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
                  font-size: calc(32px * 0.875);
                  line-height: calc(34px * 0.875); } }
              @media screen and (min-width: 641px) and (min-width: 1920px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_title .RaoProductItem__details_textBlock_title_Sub {
                  font-size: 32px;
                  line-height: 34px; } }
              @media screen and (min-width: 641px) and (min-width: 641px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
                  font-size: calc(32px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1140px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
                  font-size: calc(32px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1440px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
                  font-size: calc(32px * 0.75);
                  line-height: calc(34px * 0.75); } }
              @media screen and (min-width: 641px) and (min-width: 1680px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
                  font-size: calc(32px * 0.875);
                  line-height: calc(34px * 0.875); } }
              @media screen and (min-width: 641px) and (min-width: 1920px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price {
                  font-size: 32px;
                  line-height: 34px; } }
              @media screen and (min-width: 641px) and (min-width: 641px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
                  font-size: calc(18px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1140px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
                  font-size: calc(18px * 0.62);
                  line-height: calc(34px * 0.62); } }
              @media screen and (min-width: 641px) and (min-width: 1440px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
                  font-size: calc(18px * 0.75);
                  line-height: calc(34px * 0.75); } }
              @media screen and (min-width: 641px) and (min-width: 1680px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
                  font-size: calc(18px * 0.875);
                  line-height: calc(34px * 0.875); } }
              @media screen and (min-width: 641px) and (min-width: 1920px) {
                .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_priceWrapper .RaoProductItem__details_textBlock_price--compareAt {
                  font-size: 18px;
                  line-height: 34px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
                font-size: calc(24px * 0.62);
                line-height: calc(28px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
                font-size: calc(24px * 0.62);
                line-height: calc(28px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
                font-size: calc(24px * 0.75);
                line-height: calc(28px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
                font-size: calc(24px * 0.875);
                line-height: calc(28px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoCollectionProductList .RaoProductItem .RaoProductItem__Wrapper .RaoProductItem__details .RaoProductItem__details_textBlock .RaoProductItem__details_textBlock_description {
                font-size: 24px;
                line-height: 28px; } }
/**
 * ----------------------------------------------------------------------------
 * Rao big product item styles
 * ----------------------------------------------------------------------------
*/
.RaoBigProductItem {
  width: 100%;
  padding: 20% 4.1%;
  background: linear-gradient(89.67deg, #fddbc3 8.74%, #fbf0e9 80.92%); }
  .RaoBigProductItem .RaoBigProductItem__Wrapper {
    display: flex;
    flex-direction: column; }
    .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_image {
        width: 100%; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_image .ProductItem__Image {
          object-fit: cover;
          object-position: center;
          border-radius: 18px; }
      .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock {
        width: 100%;
        margin-top: 10%; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
          font-weight: 700;
          font-size: 27px;
          line-height: 32px;
          max-width: 100%;
          text-align: start;
          letter-spacing: 0.84px;
          padding: 0 3%;
          color: #ffffff; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
            font-size: 21px;
            line-height: 24px;
            font-weight: 300;
            letter-spacing: 0.84px;
            color: #ffffff; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper {
          display: flex;
          margin: 4% 0;
          padding: 0 3%; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
            font-weight: 600;
            color: #007F7B;
            font-size: 26px;
            line-height: 29px;
            letter-spacing: 0.84px; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
            font-weight: 300;
            font-size: 16px;
            line-height: 29px;
            letter-spacing: 0.75px;
            text-decoration-line: line-through;
            margin-left: 5px; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.75px; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper > div:first-child {
            margin-top: 20%; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper > .Button {
            height: 62px;
            width: 100%;
            max-width: 100%; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__AddToCart {
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            margin-top: 10.5%; }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__AddToCart > svg {
              margin-left: 10px;
              fill: #ffffff; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__view {
            margin-top: 8%;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            background-color: transparent;
            color: var(--button-background);
            border: 2px solid var(--button-background); }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__view:hover {
              cursor: pointer; }
    .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__review > div:first-child {
      margin-top: 19%; }
  .RaoBigProductItem .SingleProductReview_link {
    margin-bottom: -10%; }

@media screen and (min-width: 641px) {
  .RaoBigProductItem {
    width: 100%;
    padding: 5.4% 9.2%; }
    .RaoBigProductItem .RaoBigProductItem__Wrapper {
      display: flex;
      flex-direction: column; }
      .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_image {
          width: 35%;
          padding-top: 0; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_image .ProductItem__Image {
            object-fit: cover;
            object-position: center;
            border-radius: 60px; }
        .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock {
          width: 50%;
          margin-top: 0; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
            padding: 0;
            font-weight: 700;
            text-align: left;
            letter-spacing: 1px; }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
              font-weight: 300;
              letter-spacing: 1px; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper {
            display: flex;
            margin-bottom: 4%;
            margin-top: 6%;
            padding: 0; }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
              font-weight: 700;
              color: #007F7B;
              letter-spacing: 1px; }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
              font-weight: 200;
              letter-spacing: 0.75px;
              text-decoration-line: line-through;
              margin-left: 10px; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
            font-weight: 200;
            letter-spacing: 0.75px; }
          .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper {
            display: flex;
            flex-direction: row;
            margin-top: 8%; }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__AddToCart {
              max-width: 260px;
              font-weight: 600;
              font-size: 16px;
              line-height: 28px;
              margin-top: 0; }
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__AddToCart > svg {
                margin-left: 10px; }
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__view {
              max-width: 260px;
              margin-left: 11%;
              margin-top: 0;
              font-weight: 600;
              font-size: 16px;
              line-height: 28px; }
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_buttonsWrapper .RaoBigProductItem__details_textBlock_button__view:hover {
                cursor: pointer; }
      .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__review > div:first-child {
        margin-top: 5%; }
    .RaoBigProductItem .SingleProductReview_link {
      margin-bottom: -2%; } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
              font-size: calc(32px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
              font-size: calc(32px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
              font-size: calc(32px * 0.75);
              line-height: calc(34px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
              font-size: calc(32px * 0.875);
              line-height: calc(34px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title {
              font-size: 32px;
              line-height: 34px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
                font-size: calc(32px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
                font-size: calc(32px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_title .RaoBigProductItem__details_textBlock_title_Sub {
                font-size: 32px;
                line-height: 34px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
                font-size: calc(32px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
                font-size: calc(32px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
                font-size: calc(32px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price {
                font-size: 32px;
                line-height: 34px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
                font-size: calc(18px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
                font-size: calc(18px * 0.62);
                line-height: calc(34px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
                font-size: calc(18px * 0.75);
                line-height: calc(34px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
                font-size: calc(18px * 0.875);
                line-height: calc(34px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_priceWrapper .RaoBigProductItem__details_textBlock_price--compareAt {
                font-size: 18px;
                line-height: 34px; } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
              font-size: calc(24px * 0.62);
              line-height: calc(28px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
              font-size: calc(24px * 0.62);
              line-height: calc(28px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
              font-size: calc(24px * 0.75);
              line-height: calc(28px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
              font-size: calc(24px * 0.875);
              line-height: calc(28px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            .RaoBigProductItem .RaoBigProductItem__Wrapper .RaoBigProductItem__details .RaoBigProductItem__details_textBlock .RaoBigProductItem__details_textBlock_description {
              font-size: 24px;
              line-height: 28px; } }
/**
 * ----------------------------------------------------------------------------
 * Single Product Review styles
 * ----------------------------------------------------------------------------
*/
.SingleProductReview {
  color: var(--text-color);
  padding: 3.5%;
  background: #FFFFFF;
  border-radius: 46px; }
  .SingleProductReview .jdgm-rev-widg {
    padding: 0;
    margin: 0;
    border: none; }
    .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__header {
      display: none; }
    .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev:not(:first-child) {
      display: none; }
    .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
      padding: 0;
      margin: 0;
      border: none;
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header {
        height: fit-content;
        margin: 0;
        display: grid;
        grid-template: "a b" "a c"; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
          grid-area: a;
          height: 56px;
          width: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 14px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon::after {
            display: none; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating {
          grid-area: c;
          font-size: 18px;
          margin-top: 5px;
          line-height: 18px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating .jdgm-star {
            color: #FED30E; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper {
          grid-area: b;
          display: flex;
          flex-direction: column; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
            font-size: 18px;
            line-height: 18px;
            font-weight: 700;
            letter-spacing: 0.44px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
            font-size: 14px;
            line-height: 14px;
            opacity: 1;
            font-weight: 200;
            letter-spacing: 0.44px;
            margin-top: 5px; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
          display: none; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__br {
          display: none; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__buyer-badge-wrapper {
          display: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content {
        display: none; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__pics {
          display: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__actions {
        display: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__reply {
        display: none; }
    .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate {
      display: none; }

.SingleProductReview_link {
  width: 100%;
  text-align: center;
  margin-top: 6%; }
  .SingleProductReview_link > a {
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    letter-spacing: 0.75px;
    text-decoration: underline; }

@media screen and (min-width: 641px) {
  .SingleProductReview {
    color: var(--text-color);
    padding: 2% 3.5%;
    background: #FFFFFF;
    border-radius: 46px; }
    .SingleProductReview .jdgm-rev-widg {
      padding: 0;
      margin: 0;
      border: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__header {
        display: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev:not(:first-child) {
        display: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev {
        padding: 0;
        margin: 0;
        border: none;
        display: flex;
        justify-content: space-between;
        width: 100%; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header {
          margin: 0;
          display: grid;
          grid-template: "a b" "a c"; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__icon {
            grid-area: a;
            height: 56px;
            width: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 14px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__rating {
            grid-area: c;
            font-size: 20px;
            margin-top: 10px;
            line-height: 20px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper {
            grid-area: b;
            display: flex;
            flex-direction: column; }
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
              font-weight: 700;
              letter-spacing: 0.44px; }
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
              opacity: 1;
              font-weight: 200;
              letter-spacing: 0.44px;
              margin-top: 5px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__timestamp {
            display: none; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__br {
            display: none; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content {
          display: flex;
          width: 72%; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__custom-form {
            display: none; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
            width: 48%;
            font-weight: bold;
            letter-spacing: 1px; }
          .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
            width: 54%;
            font-weight: 200;
            letter-spacing: 1px; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__actions {
          display: none; }
        .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__reply {
          display: none; }
      .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-paginate {
        display: none; }

  .SingleProductReview_link {
    width: 100%;
    text-align: center;
    margin-top: 1.5%; }
    .SingleProductReview_link > a {
      font-weight: 600;
      letter-spacing: 0.75px;
      text-decoration: underline; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
                font-size: calc(24px * 0.62);
                line-height: calc(24px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
                font-size: calc(24px * 0.62);
                line-height: calc(24px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
                font-size: calc(24px * 0.75);
                line-height: calc(24px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
                font-size: calc(24px * 0.875);
                line-height: calc(24px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__author {
                font-size: 24px;
                line-height: 24px; } }
            @media screen and (min-width: 641px) and (min-width: 641px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
                font-size: calc(18px * 0.62);
                line-height: calc(21px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1140px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
                font-size: calc(18px * 0.62);
                line-height: calc(21px * 0.62); } }
            @media screen and (min-width: 641px) and (min-width: 1440px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
                font-size: calc(18px * 0.75);
                line-height: calc(21px * 0.75); } }
            @media screen and (min-width: 641px) and (min-width: 1680px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
                font-size: calc(18px * 0.875);
                line-height: calc(21px * 0.875); } }
            @media screen and (min-width: 641px) and (min-width: 1920px) {
              .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__header .jdgm-rev__author-wrapper .jdgm-rev__location {
                font-size: 18px;
                line-height: 21px; } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
              font-size: calc(32px * 0.62);
              line-height: calc(50px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
              font-size: calc(32px * 0.62);
              line-height: calc(50px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
              font-size: calc(32px * 0.75);
              line-height: calc(50px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
              font-size: calc(32px * 0.875);
              line-height: calc(50px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__title {
              font-size: 32px;
              line-height: 50px; } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
              font-size: calc(20px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
              font-size: calc(20px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
              font-size: calc(20px * 0.75);
              line-height: calc(34px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
              font-size: calc(20px * 0.875);
              line-height: calc(34px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            .SingleProductReview .jdgm-rev-widg .jdgm-rev-widg__body .jdgm-rev-widg__reviews .jdgm-rev .jdgm-rev__content .jdgm-rev__body {
              font-size: 20px;
              line-height: 34px; } }
    @media screen and (min-width: 641px) and (min-width: 641px) {
      .SingleProductReview_link > a {
        font-size: calc(16px * 0.62);
        line-height: calc(28px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1140px) {
      .SingleProductReview_link > a {
        font-size: calc(16px * 0.62);
        line-height: calc(28px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1440px) {
      .SingleProductReview_link > a {
        font-size: calc(16px * 0.75);
        line-height: calc(28px * 0.75); } }
    @media screen and (min-width: 641px) and (min-width: 1680px) {
      .SingleProductReview_link > a {
        font-size: calc(16px * 0.875);
        line-height: calc(28px * 0.875); } }
    @media screen and (min-width: 641px) and (min-width: 1920px) {
      .SingleProductReview_link > a {
        font-size: 16px;
        line-height: 28px; } }

/**
 * ----------------------------------------------------------------------------
 * About page styles
 * ----------------------------------------------------------------------------
*/
.Rao_about {
  padding: 25% 4%;
  position: relative;
  padding-bottom: 0; }
  .Rao_about .Rao_about_block_image {
    position: absolute;
    width: 100%;
    top: -1%;
    left: -6%; }
    .Rao_about .Rao_about_block_image > svg {
      width: 100%;
      height: auto; }
  .Rao_about .Rao_about_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4%; }
    .Rao_about .Rao_about_header .Rao_about_header_title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 3%;
      text-align: center;
      letter-spacing: 1px; }
    .Rao_about .Rao_about_header .Rao_about_header_text {
      max-width: 100%;
      font-weight: 200;
      font-size: 19px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 1px; }
  .Rao_about .Rao_about_block1 {
    margin: 12% 0;
    padding: 12% 10%;
    box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
    border-radius: 32px; }
    .Rao_about .Rao_about_block1 .Rao_about_block1_title {
      font-size: 21px;
      line-height: 22px;
      letter-spacing: 1px;
      font-weight: 600;
      margin-bottom: 10%; }
    .Rao_about .Rao_about_block1 .Rao_about_block1_text {
      font-weight: 200;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 0.44px;
      margin-bottom: 2%; }
  .Rao_about .Rao_about_block2 {
    width: 100%;
    display: flex;
    flex-direction: column; }
    .Rao_about .Rao_about_block2 .Rao_about_block2_imageWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_imageWrapper .Rao_about_block2_image {
        width: 48%;
        margin: 0;
        object-fit: cover;
        object-position: center;
        border-radius: 32px;
        background-color: #FACEAF;
        position: relative;
        left: -8%; }
        .Rao_about .Rao_about_block2 .Rao_about_block2_imageWrapper .Rao_about_block2_image > img {
          mix-blend-mode: overlay; }
    .Rao_about .Rao_about_block2 .Rao_about_block2_addImage1 {
      position: absolute;
      top: 13%;
      left: -28%;
      width: 75%; }
    .Rao_about .Rao_about_block2 .Rao_about_block2_addImage2 {
      position: absolute;
      top: 12%;
      left: -17%;
      width: 90%; }
    .Rao_about .Rao_about_block2 .Rao_about_block2_arrow {
      position: absolute;
      top: -15%;
      left: 41%;
      width: 54%; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_arrow > svg {
        width: 100%;
        height: auto; }
    .Rao_about .Rao_about_block2 .Rao_about_block2_text {
      margin-top: 26%;
      padding: 8% 7%;
      width: 100%;
      box-shadow: 0px 4px 4px rgba(129, 139, 136, 0.25);
      backdrop-filter: blur(46px);
      border-radius: 32px;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.44px;
      text-align: center; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_text .Rao_about_block2_arrow2 {
        position: absolute;
        top: 106%;
        left: 29%;
        width: 19%; }
        .Rao_about .Rao_about_block2 .Rao_about_block2_text .Rao_about_block2_arrow2 > svg {
          width: 100%;
          height: auto; }
  .Rao_about .Rao_about_block3 {
    width: 100%;
    margin-top: 30%;
    display: flex;
    flex-direction: column-reverse; }
    .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper {
      width: 100%;
      padding: 10%;
      margin-top: 10%;
      box-shadow: 0px 4px 4px rgba(129, 139, 136, 0.25);
      backdrop-filter: blur(46px);
      border-radius: 32px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
        width: 100%;
        margin-bottom: 10%;
        font-weight: 500;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        letter-spacing: 1px; }
      .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_icons {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: dense;
        direction: rtl;
        grid-gap: 8px;
        width: 85%;
        margin-bottom: 8%; }
        .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_icons > svg {
          width: 100%;
          height: auto; }
    .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper {
      width: 100%; }
      .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.45px; }
      .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
        margin-top: 6%;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.45px; }
  .Rao_about .Rao_about_block4 {
    padding: 15% 0; }
    .Rao_about .Rao_about_block4 .Rao_about_block4_title {
      width: 100%;
      font-size: 24px;
      line-height: 50px;
      letter-spacing: 1px; }
    .Rao_about .Rao_about_block4 .Rao_about_block4_text {
      width: 100%;
      font-weight: 200;
      letter-spacing: 1px;
      font-size: 16px;
      line-height: 24px; }
  .Rao_about .Rao_about_block5 {
    padding: 12% 0;
    padding-top: 50%;
    position: relative; }
    .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
        width: 60%;
        margin-left: auto;
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px; }
      .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact {
        max-width: 100%;
        display: flex;
        border: 1px solid;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 8% 4.5%;
        margin-top: 25%;
        background: var(--background);
        z-index: 1; }
        .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_icon {
          min-width: 17%;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
        .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
          font-weight: 200;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px; }

@media screen and (min-width: 641px) {
  .Rao_about {
    padding: 13.2% 15%;
    position: relative;
    padding-bottom: 0; }
    .Rao_about .Rao_about_block_image {
      position: absolute;
      width: 127%;
      bottom: -6%;
      top: auto;
      left: -22%; }
      .Rao_about .Rao_about_block_image > svg {
        width: 100%;
        height: auto; }
    .Rao_about .Rao_about_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 0; }
      .Rao_about .Rao_about_header .Rao_about_header_title {
        margin-bottom: 3%;
        text-align: center;
        letter-spacing: 1px; }
      .Rao_about .Rao_about_header .Rao_about_header_text {
        max-width: 56%;
        font-weight: 200;
        text-align: center;
        letter-spacing: 1px; }
    .Rao_about .Rao_about_block1 {
      margin: 8% 0;
      padding: 4.8% 5.3%;
      box-shadow: 0px 4px 4px rgba(98, 97, 117, 0.29);
      border-radius: 32px; }
      .Rao_about .Rao_about_block1 .Rao_about_block1_title {
        font-weight: 400;
        letter-spacing: 1px;
        margin-bottom: 2%; }
      .Rao_about .Rao_about_block1 .Rao_about_block1_text {
        font-weight: 200;
        letter-spacing: 1px;
        margin-bottom: 2%; }
    .Rao_about .Rao_about_block2 {
      width: 100%;
      display: flex;
      flex-direction: row; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_imageWrapper {
        width: 40%;
        display: flex;
        align-items: center;
        position: relative; }
        .Rao_about .Rao_about_block2 .Rao_about_block2_imageWrapper .Rao_about_block2_image {
          width: 70%;
          margin: 0;
          object-fit: cover;
          object-position: center;
          border-radius: 32px;
          background-color: #FACEAF;
          position: relative;
          left: 0; }
          .Rao_about .Rao_about_block2 .Rao_about_block2_imageWrapper .Rao_about_block2_image > img {
            mix-blend-mode: overlay; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_addImage1 {
        position: absolute;
        top: 38%;
        left: -26%;
        width: 100%; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_addImage2 {
        position: absolute;
        top: 39%;
        left: -6%;
        width: 110%;
        max-width: 110%; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_arrow {
        position: absolute;
        top: -10%;
        left: 18%;
        width: 100%; }
        .Rao_about .Rao_about_block2 .Rao_about_block2_arrow > svg {
          width: 100%;
          height: auto; }
      .Rao_about .Rao_about_block2 .Rao_about_block2_text {
        margin-top: 0;
        padding: 5% 7%;
        width: 60%;
        box-shadow: 0px 4px 4px rgba(129, 139, 136, 0.25);
        backdrop-filter: blur(46px);
        border-radius: 32px;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: start; }
        .Rao_about .Rao_about_block2 .Rao_about_block2_text .Rao_about_block2_arrow2 {
          position: absolute;
          top: 105%;
          left: 33%;
          width: 14%; }
          .Rao_about .Rao_about_block2 .Rao_about_block2_text .Rao_about_block2_arrow2 > svg {
            width: 100%;
            height: auto; }
    .Rao_about .Rao_about_block3 {
      width: 100%;
      margin-top: 13%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper {
        width: 50%;
        padding: 4%;
        margin-top: 0;
        box-shadow: 0px 4px 4px rgba(129, 139, 136, 0.25);
        backdrop-filter: blur(46px);
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        align-items: center; }
        .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
          width: 100%;
          margin-bottom: 3%;
          font-weight: 500;
          text-align: center;
          letter-spacing: 1px; }
        .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_icons {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-auto-flow: dense;
          direction: rtl;
          grid-gap: 20px;
          width: 85%;
          margin-bottom: 8%; }
          .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_icons > svg {
            width: 100%;
            height: auto; }
      .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper {
        width: 43%; }
        .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
          font-weight: 200;
          text-align: start;
          letter-spacing: 1px; }
        .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
          margin-top: 6%;
          text-align: start;
          letter-spacing: 1px; }
    .Rao_about .Rao_about_block4 {
      padding: 12% 0; }
      .Rao_about .Rao_about_block4 .Rao_about_block4_title {
        width: 64%;
        letter-spacing: 1px; }
      .Rao_about .Rao_about_block4 .Rao_about_block4_text {
        width: 64%;
        font-weight: 200;
        letter-spacing: 1px; }
    .Rao_about .Rao_about_block5 {
      padding: 12% 0;
      position: relative; }
      .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper {
        display: flex;
        flex-direction: column;
        width: 64%;
        margin-left: auto; }
        .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
          width: 100%;
          margin: auto;
          font-weight: 200;
          letter-spacing: 1px; }
        .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact {
          max-width: 75%;
          display: flex;
          border: 2px solid;
          box-sizing: border-box;
          border-radius: 60px;
          padding: 4.5%;
          margin-top: 8%; }
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_icon {
            min-width: 15%;
            display: flex;
            align-items: center;
            justify-content: flex-start; }
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
            font-weight: 400;
            letter-spacing: 1px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_header .Rao_about_header_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_header .Rao_about_header_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_header .Rao_about_header_title {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_header .Rao_about_header_title {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_header .Rao_about_header_title {
          font-size: 48px;
          line-height: 50px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_header .Rao_about_header_text {
          font-size: calc(32px * 0.62);
          line-height: calc(43px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_header .Rao_about_header_text {
          font-size: calc(32px * 0.62);
          line-height: calc(43px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_header .Rao_about_header_text {
          font-size: calc(32px * 0.75);
          line-height: calc(43px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_header .Rao_about_header_text {
          font-size: calc(32px * 0.875);
          line-height: calc(43px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_header .Rao_about_header_text {
          font-size: 32px;
          line-height: 43px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_title {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_title {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_title {
          font-size: 48px;
          line-height: 50px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_text {
          font-size: calc(34px * 0.62);
          line-height: calc(54px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_text {
          font-size: calc(34px * 0.62);
          line-height: calc(54px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_text {
          font-size: calc(34px * 0.75);
          line-height: calc(54px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_text {
          font-size: calc(34px * 0.875);
          line-height: calc(54px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_block1 .Rao_about_block1_text {
          font-size: 34px;
          line-height: 54px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_block2 .Rao_about_block2_text {
          font-size: calc(34px * 0.62);
          line-height: calc(54px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_block2 .Rao_about_block2_text {
          font-size: calc(34px * 0.62);
          line-height: calc(54px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_block2 .Rao_about_block2_text {
          font-size: calc(34px * 0.75);
          line-height: calc(54px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_block2 .Rao_about_block2_text {
          font-size: calc(34px * 0.875);
          line-height: calc(54px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_block2 .Rao_about_block2_text {
          font-size: 34px;
          line-height: 54px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
            font-size: calc(109px * 0.62);
            line-height: calc(109px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
            font-size: calc(109px * 0.62);
            line-height: calc(109px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
            font-size: calc(109px * 0.75);
            line-height: calc(109px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
            font-size: calc(109px * 0.875);
            line-height: calc(109px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_peopleСounterWrapper .Rao_about_block3_peopleСounter_header {
            font-size: 109px;
            line-height: 109px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
            font-size: calc(34px * 0.62);
            line-height: calc(54px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
            font-size: calc(34px * 0.62);
            line-height: calc(54px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
            font-size: calc(34px * 0.75);
            line-height: calc(54px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
            font-size: calc(34px * 0.875);
            line-height: calc(54px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text1 {
            font-size: 34px;
            line-height: 54px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
            font-size: calc(45px * 0.62);
            line-height: calc(54px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
            font-size: calc(45px * 0.62);
            line-height: calc(54px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
            font-size: calc(45px * 0.75);
            line-height: calc(54px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
            font-size: calc(45px * 0.875);
            line-height: calc(54px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .Rao_about .Rao_about_block3 .Rao_about_block3_textWrapper .Rao_about_block3_text2 {
            font-size: 45px;
            line-height: 54px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_title {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_title {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_title {
          font-size: 48px;
          line-height: 50px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_text {
          font-size: calc(34px * 0.62);
          line-height: calc(54px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_text {
          font-size: calc(34px * 0.62);
          line-height: calc(54px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_text {
          font-size: calc(34px * 0.75);
          line-height: calc(54px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_text {
          font-size: calc(34px * 0.875);
          line-height: calc(54px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Rao_about .Rao_about_block4 .Rao_about_block4_text {
          font-size: 34px;
          line-height: 54px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
            font-size: calc(34px * 0.62);
            line-height: calc(54px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
            font-size: calc(34px * 0.62);
            line-height: calc(54px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
            font-size: calc(34px * 0.75);
            line-height: calc(54px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
            font-size: calc(34px * 0.875);
            line-height: calc(54px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_text {
            font-size: 34px;
            line-height: 54px; } }
          @media screen and (min-width: 641px) and (min-width: 641px) {
            .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
              font-size: calc(18px * 0.62);
              line-height: calc(22px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1140px) {
            .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
              font-size: calc(18px * 0.62);
              line-height: calc(22px * 0.62); } }
          @media screen and (min-width: 641px) and (min-width: 1440px) {
            .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
              font-size: calc(18px * 0.75);
              line-height: calc(22px * 0.75); } }
          @media screen and (min-width: 641px) and (min-width: 1680px) {
            .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
              font-size: calc(18px * 0.875);
              line-height: calc(22px * 0.875); } }
          @media screen and (min-width: 641px) and (min-width: 1920px) {
            .Rao_about .Rao_about_block5 .Rao_about_block5_wrapper .Rao_about_block5_fact .Rao_about_block5_fact_text {
              font-size: 18px;
              line-height: 22px; } }

/**
 * ----------------------------------------------------------------------------
 * About blocks styles
 * ----------------------------------------------------------------------------
*/
.AboutPageBlocks {
  padding: 13% 4%;
  padding-bottom: 0; }
  .AboutPageBlocks .AboutPageBlocks_title {
    width: 74%;
    margin: auto;
    text-align: center;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.44px;
    padding-bottom: 9%; }
  .AboutPageBlocks .AboutPageBlocks_blocks {
    display: flex;
    flex-direction: column; }
    .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 15%; }
      .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: 50px;
        letter-spacing: 1px; }
      .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
        width: 100%;
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px; }

@media screen and (min-width: 641px) {
  .AboutPageBlocks {
    padding: 5% 13%;
    padding-bottom: 0; }
    .AboutPageBlocks .AboutPageBlocks_title {
      width: 100%;
      margin: 0;
      text-align: center;
      letter-spacing: 1px;
      padding-bottom: 9%; }
    .AboutPageBlocks .AboutPageBlocks_blocks {
      display: flex;
      flex-direction: column; }
      .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: 7%; }
        .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
          width: 30%;
          display: flex;
          align-items: center;
          letter-spacing: 1px; }
        .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
          width: 70%;
          font-weight: 200;
          letter-spacing: 1px; } }
    @media screen and (min-width: 641px) and (min-width: 641px) {
      .AboutPageBlocks .AboutPageBlocks_title {
        font-size: calc(48px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1140px) {
      .AboutPageBlocks .AboutPageBlocks_title {
        font-size: calc(48px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 641px) and (min-width: 1440px) {
      .AboutPageBlocks .AboutPageBlocks_title {
        font-size: calc(48px * 0.75);
        line-height: calc(50px * 0.75); } }
    @media screen and (min-width: 641px) and (min-width: 1680px) {
      .AboutPageBlocks .AboutPageBlocks_title {
        font-size: calc(48px * 0.875);
        line-height: calc(50px * 0.875); } }
    @media screen and (min-width: 641px) and (min-width: 1920px) {
      .AboutPageBlocks .AboutPageBlocks_title {
        font-size: 48px;
        line-height: 50px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
            font-size: calc(48px * 0.75);
            line-height: calc(50px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
            font-size: calc(48px * 0.875);
            line-height: calc(50px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_title {
            font-size: 48px;
            line-height: 50px; } }
        @media screen and (min-width: 641px) and (min-width: 641px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
            font-size: calc(24px * 0.62);
            line-height: calc(43px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1140px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
            font-size: calc(24px * 0.62);
            line-height: calc(43px * 0.62); } }
        @media screen and (min-width: 641px) and (min-width: 1440px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
            font-size: calc(24px * 0.75);
            line-height: calc(43px * 0.75); } }
        @media screen and (min-width: 641px) and (min-width: 1680px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
            font-size: calc(24px * 0.875);
            line-height: calc(43px * 0.875); } }
        @media screen and (min-width: 641px) and (min-width: 1920px) {
          .AboutPageBlocks .AboutPageBlocks_blocks .AboutPageBlocks_block_wrapper .AboutPageBlocks_block_wrapper_content {
            font-size: 24px;
            line-height: 43px; } }

/**
 * ----------------------------------------------------------------------------
 * Rao Article Template styles
 * ----------------------------------------------------------------------------
*/
.RaoArticle .RaoArticle__ImageWrapper {
  height: 32vw; }
  .RaoArticle .RaoArticle__ImageWrapper .RaoArticle__Image {
    width: 100%;
    height: 100%;
    padding: 5.8%;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
.RaoArticle .RaoArticle_header_articlTitle {
  width: 56%;
  max-width: 56%;
  padding: 3%;
  background: rgba(255, 255, 255, 0.83);
  backdrop-filter: blur(28px);
  border-radius: 28px;
  font-weight: 300;
  letter-spacing: 1px; }
  @media screen and (min-width: 641px) {
    .RaoArticle .RaoArticle_header_articlTitle {
      font-size: calc(48px * 0.62);
      line-height: calc(50px * 0.62); } }
  @media screen and (min-width: 1140px) {
    .RaoArticle .RaoArticle_header_articlTitle {
      font-size: calc(48px * 0.62);
      line-height: calc(50px * 0.62); } }
  @media screen and (min-width: 1440px) {
    .RaoArticle .RaoArticle_header_articlTitle {
      font-size: calc(48px * 0.75);
      line-height: calc(50px * 0.75); } }
  @media screen and (min-width: 1680px) {
    .RaoArticle .RaoArticle_header_articlTitle {
      font-size: calc(48px * 0.875);
      line-height: calc(50px * 0.875); } }
  @media screen and (min-width: 1920px) {
    .RaoArticle .RaoArticle_header_articlTitle {
      font-size: 48px;
      line-height: 50px; } }
  .RaoArticle .RaoArticle_header_articlTitle .RaoArticle_header_articlAutor {
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: 4%; }
    @media screen and (min-width: 641px) {
      .RaoArticle .RaoArticle_header_articlTitle .RaoArticle_header_articlAutor {
        font-size: calc(32px * 0.62);
        line-height: calc(43px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoArticle .RaoArticle_header_articlTitle .RaoArticle_header_articlAutor {
        font-size: calc(32px * 0.62);
        line-height: calc(43px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoArticle .RaoArticle_header_articlTitle .RaoArticle_header_articlAutor {
        font-size: calc(32px * 0.75);
        line-height: calc(43px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoArticle .RaoArticle_header_articlTitle .RaoArticle_header_articlAutor {
        font-size: calc(32px * 0.875);
        line-height: calc(43px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoArticle .RaoArticle_header_articlTitle .RaoArticle_header_articlAutor {
        font-size: 32px;
        line-height: 43px; } }
.RaoArticle .RaoArticle__Wrapper {
  max-width: calc(1233px + 10%);
  padding: 0 5%;
  padding-bottom: 4%;
  margin: 0 auto; }
  .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper {
    padding: 3% 0;
    font-weight: 400;
    letter-spacing: 1px; }
    @media screen and (min-width: 641px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper {
        font-size: calc(18px * 0.62);
        line-height: calc(34px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper {
        font-size: calc(18px * 0.62);
        line-height: calc(34px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper {
        font-size: calc(18px * 0.75);
        line-height: calc(34px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper {
        font-size: calc(18px * 0.875);
        line-height: calc(34px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper {
        font-size: 18px;
        line-height: 34px; } }
    .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper .RaoArticle_route_articleTitle {
      font-weight: 200;
      letter-spacing: 1px; }
      @media screen and (min-width: 641px) {
        .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper .RaoArticle_route_articleTitle {
          font-size: calc(18px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 1140px) {
        .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper .RaoArticle_route_articleTitle {
          font-size: calc(18px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 1440px) {
        .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper .RaoArticle_route_articleTitle {
          font-size: calc(18px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 1680px) {
        .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper .RaoArticle_route_articleTitle {
          font-size: calc(18px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 1920px) {
        .RaoArticle .RaoArticle__Wrapper .RaoArticle_routeWrapper .RaoArticle_route_articleTitle {
          font-size: 18px;
          line-height: 34px; } }
  .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > p > span {
    font-weight: 300 !important;
    letter-spacing: 0.75px; }
    @media screen and (min-width: 641px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > p > span {
        font-size: calc(22px * 0.62);
        line-height: calc(31px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > p > span {
        font-size: calc(22px * 0.62);
        line-height: calc(31px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > p > span {
        font-size: calc(22px * 0.75);
        line-height: calc(31px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > p > span {
        font-size: calc(22px * 0.875);
        line-height: calc(31px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > p > span {
        font-size: 22px;
        line-height: 31px; } }
  .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > h1, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h2, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h3, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h4, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h5, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h6 {
    font-weight: 400 !important;
    letter-spacing: 1px;
    margin: 1.2em 0 .8em; }
    @media screen and (min-width: 641px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > h1, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h2, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h3, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h4, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h5, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h6 {
        font-size: calc(48px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > h1, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h2, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h3, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h4, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h5, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h6 {
        font-size: calc(48px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > h1, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h2, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h3, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h4, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h5, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h6 {
        font-size: calc(48px * 0.75);
        line-height: calc(50px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > h1, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h2, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h3, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h4, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h5, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h6 {
        font-size: calc(48px * 0.875);
        line-height: calc(50px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body > h1, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h2, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h3, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h4, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h5, .RaoArticle .RaoArticle__Wrapper .RaoArticle__Content .RaoArticle__Body h6 {
        font-size: 48px;
        line-height: 50px; } }
.RaoArticle .RaoArticle__CommentsWrapper {
  padding: 5% 9%; }
  .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle_heading {
    margin-bottom: 2.5%;
    letter-spacing: 0.75px;
    color: #000000; }
    @media screen and (min-width: 641px) {
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle_heading {
        font-size: calc(24px * 0.62);
        line-height: calc(34px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle_heading {
        font-size: calc(24px * 0.62);
        line-height: calc(34px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle_heading {
        font-size: calc(24px * 0.75);
        line-height: calc(34px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle_heading {
        font-size: calc(24px * 0.875);
        line-height: calc(34px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle_heading {
        font-size: 24px;
        line-height: 34px; } }
  .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList {
    background: #FFFFFF;
    border-radius: 32px;
    padding: 3.5% 4%; }
    .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Body {
      font-weight: 200;
      letter-spacing: 0.75px; }
      @media screen and (min-width: 641px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Body {
          font-size: calc(22px * 0.62);
          line-height: calc(31px * 0.62); } }
      @media screen and (min-width: 1140px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Body {
          font-size: calc(22px * 0.62);
          line-height: calc(31px * 0.62); } }
      @media screen and (min-width: 1440px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Body {
          font-size: calc(22px * 0.75);
          line-height: calc(31px * 0.75); } }
      @media screen and (min-width: 1680px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Body {
          font-size: calc(22px * 0.875);
          line-height: calc(31px * 0.875); } }
      @media screen and (min-width: 1920px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Body {
          font-size: 22px;
          line-height: 31px; } }
    .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta {
      margin-top: 2%;
      max-width: 24%;
      letter-spacing: 0.75px;
      font-weight: 200;
      color: #000000; }
      @media screen and (min-width: 641px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta {
          font-size: calc(22px * 0.62);
          line-height: calc(31px * 0.62); } }
      @media screen and (min-width: 1140px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta {
          font-size: calc(22px * 0.62);
          line-height: calc(31px * 0.62); } }
      @media screen and (min-width: 1440px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta {
          font-size: calc(22px * 0.75);
          line-height: calc(31px * 0.75); } }
      @media screen and (min-width: 1680px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta {
          font-size: calc(22px * 0.875);
          line-height: calc(31px * 0.875); } }
      @media screen and (min-width: 1920px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta {
          font-size: 22px;
          line-height: 31px; } }
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta .RaoArticleComment__Author {
        font-weight: 400; }
      .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__Comments .RaoArticle__CommentList .RaoArticleComment__Meta .RaoArticleComment__Date {
        font-weight: 400; }
  .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper {
    margin: 5.3% 0; }
    .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper .RaoArticle__CommentForm .Form__Hint {
      padding: 0 1%;
      font-weight: 200;
      letter-spacing: 0.75px;
      color: #000000; }
      @media screen and (min-width: 641px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper .RaoArticle__CommentForm .Form__Hint {
          font-size: calc(16px * 0.62);
          line-height: calc(28px * 0.62); } }
      @media screen and (min-width: 1140px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper .RaoArticle__CommentForm .Form__Hint {
          font-size: calc(16px * 0.62);
          line-height: calc(28px * 0.62); } }
      @media screen and (min-width: 1440px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper .RaoArticle__CommentForm .Form__Hint {
          font-size: calc(16px * 0.75);
          line-height: calc(28px * 0.75); } }
      @media screen and (min-width: 1680px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper .RaoArticle__CommentForm .Form__Hint {
          font-size: calc(16px * 0.875);
          line-height: calc(28px * 0.875); } }
      @media screen and (min-width: 1920px) {
        .RaoArticle .RaoArticle__CommentsWrapper .RaoArticle__CommentFormWrapper .RaoArticle__CommentForm .Form__Hint {
          font-size: 16px;
          line-height: 28px; } }
.RaoArticle .RaoArticleNav {
  background: transparent;
  padding: 5% 9.5% 10%; }
  .RaoArticle .RaoArticleNav .Container {
    margin: 0;
    padding: 0;
    max-width: 100%; }
    .RaoArticle .RaoArticleNav .Container .RaoArticleNav_title {
      width: 100%;
      color: var(--text-color);
      font-weight: 300;
      text-align: center;
      letter-spacing: 1px;
      padding-bottom: 5%; }
      @media screen and (min-width: 641px) {
        .RaoArticle .RaoArticleNav .Container .RaoArticleNav_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 1140px) {
        .RaoArticle .RaoArticleNav .Container .RaoArticleNav_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 1440px) {
        .RaoArticle .RaoArticleNav .Container .RaoArticleNav_title {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 1680px) {
        .RaoArticle .RaoArticleNav .Container .RaoArticleNav_title {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 1920px) {
        .RaoArticle .RaoArticleNav .Container .RaoArticleNav_title {
          font-size: 48px;
          line-height: 50px; } }
.RaoArticle .RaoArticle_categories {
  padding: 3% 3% 7%; }
  .RaoArticle .RaoArticle_categories .RaoArticle_categories_title {
    width: 100%;
    color: var(--text-color);
    font-weight: 300;
    text-align: center;
    letter-spacing: 1px;
    padding-bottom: 2%; }
    @media screen and (min-width: 641px) {
      .RaoArticle .RaoArticle_categories .RaoArticle_categories_title {
        font-size: calc(48px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoArticle .RaoArticle_categories .RaoArticle_categories_title {
        font-size: calc(48px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoArticle .RaoArticle_categories .RaoArticle_categories_title {
        font-size: calc(48px * 0.75);
        line-height: calc(50px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoArticle .RaoArticle_categories .RaoArticle_categories_title {
        font-size: calc(48px * 0.875);
        line-height: calc(50px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoArticle .RaoArticle_categories .RaoArticle_categories_title {
        font-size: 48px;
        line-height: 50px; } }
  .RaoArticle .RaoArticle_categories .Grid {
    margin: 0 !important;
    padding: 0; }
    .RaoArticle .RaoArticle_categories .Grid .Grid__Cell {
      margin: 0 !important;
      padding: 0; }
@media screen and (max-width: 1007px) {
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Wrapper {
    display: flex;
    flex-direction: column; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Wrapper .RaoArticle_routeWrapper {
      order: 1;
      padding: 15px 0 25px 0; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Wrapper .Article__Sticky_Navigation {
      order: 5;
      margin-top: 5%; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Wrapper .RaoArticle__Content {
      order: 2; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Wrapper .RaoArticle__Author--bio-header {
      order: 3; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Wrapper .RaoArticle__Author--bio-container {
      order: 4; } }
.RaoArticle.RaoArticle__Optimised .RaoArticle__Content_Details {
  display: flex;
  align-items: center;
  margin-top: -15px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Content_Details .RaoArticle__Content_Details--avatar {
    width: 32px;
    margin-right: 15px; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Content_Details .RaoArticle__Content_Details--avatar img {
      border-radius: 50px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Content_Details .RaoArticle__Content_Details--text {
    width: calc(100% - 32px); }
@media screen and (min-width: 1140px) {
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Content {
    width: calc(100% - 400px); } }
@media screen and (min-width: 1008px) and (max-width: 1139px) {
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Content {
    width: calc(100% - 300px); } }
.RaoArticle.RaoArticle__Optimised .Article__Sticky_Navigation {
  padding: 20px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 8px 16px rgba(112, 109, 145, 0.1); }
  @media screen and (min-width: 1140px) {
    .RaoArticle.RaoArticle__Optimised .Article__Sticky_Navigation {
      width: 340px;
      float: right;
      position: sticky;
      top: 100px; } }
  @media screen and (min-width: 1008px) and (max-width: 1139px) {
    .RaoArticle.RaoArticle__Optimised .Article__Sticky_Navigation {
      width: 260px;
      float: right;
      position: sticky;
      top: 100px; } }
  @media screen and (max-width: 1007px) {
    .RaoArticle.RaoArticle__Optimised .Article__Sticky_Navigation {
      width: 100%; } }
  .RaoArticle.RaoArticle__Optimised .Article__Sticky_Navigation .Article__Sticky_Navigation--header {
    font-size: 28px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .RaoArticle.RaoArticle__Optimised .Article__Sticky_Navigation .Article__Sticky_Navigation--link {
    width: 100%;
    display: block;
    padding-top: 10px;
    font-size: 16px; }
.RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-header {
  margin: 60px 0 30px 0;
  font-size: 20px; }
.RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container {
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: 33px auto;
  grid-template-areas: 'item1 item2' 'item1 item3';
  grid-gap: 20px; }
  @media screen and (max-width: 640px) {
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container {
      grid-template-areas: 'item1 item2' 'item3 item3';
      grid-template-columns: 50px auto;
      grid-template-rows: 40px auto; } }
  @media screen and (min-width: 1008px) {
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container {
      width: calc(100% - 400px); } }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container .RaoArticle__Author--bio-avatar-container {
    grid-area: item1;
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container .RaoArticle__Author--bio-avatar-container img {
      border-radius: 200px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container .RaoArticle__Author--bio-name {
    grid-area: item2;
    font-size: 20px;
    font-weight: 600; }
    @media screen and (max-width: 640px) {
      .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container .RaoArticle__Author--bio-name {
        line-height: 50px; } }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__Author--bio-container .RaoArticle__Author--bio-description {
    grid-area: item3; }
.RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'item1 item2 item3';
  justify-items: center;
  padding: 0 5% 5% 5%;
  grid-gap: 20px;
  max-width: calc(1233px + 10%);
  margin: 0 auto; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .Button {
    max-width: 220px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .PrevArticle {
    grid-area: item1;
    justify-self: flex-start; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .PrevArticle .Article__NavIcon {
      padding: 0 10px 0 0; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .SocialSharing {
    grid-area: item2; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .NextArticle {
    grid-area: item3;
    justify-self: flex-end; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .NextArticle .Article__NavIcon {
      padding: 0 0 0 10px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .Article__SocialIcon:nth-child(2) {
    padding: 0 0 0 10px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .Article__SocialIcon:not(:nth-child(2)):not(:last-child) {
    padding: 0 5px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .Article__SocialIcon:last-child {
    padding: 0 0 0 3px; }
  .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .Product__ShareList {
    right: unset;
    z-index: 10; }
    .RaoArticle.RaoArticle__Optimised .RaoArticle__PrevNextNav-container .Product__ShareList .Product__ShareItem {
      margin: 15px auto 15px 0;
      background: #fce9dd; }
.RaoArticle.RaoArticle__Optimised .RaoArticle__CommentsWrapper {
  max-width: calc(1233px + 10%);
  margin: 0 auto;
  padding: 0 5% 5% 5%; }
@media screen and (max-width: 640px) {
  .RaoArticle.RaoArticle__Optimised .Form__Submit.Button {
    max-width: unset; } }

/**
 * ----------------------------------------------------------------------------
 * Rao Blog Item styles
 * ----------------------------------------------------------------------------
*/
.RaoBlogItem_block {
  background: transparent;
  display: flex;
  flex-direction: column; }
  .RaoBlogItem_block .RaoBlogItem_blocks_image_blocks_imageWrapper {
    width: 100%; }
    .RaoBlogItem_block .RaoBlogItem_blocks_image_blocks_imageWrapper img {
      object-fit: cover;
      object-position: center; }
  .RaoBlogItem_block .RaoBlogItem_block_title {
    font-weight: 500;
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    margin-top: 4%; }
    @media screen and (min-width: 641px) {
      .RaoBlogItem_block .RaoBlogItem_block_title {
        font-size: calc(32px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoBlogItem_block .RaoBlogItem_block_title {
        font-size: calc(32px * 0.62);
        line-height: calc(50px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoBlogItem_block .RaoBlogItem_block_title {
        font-size: calc(32px * 0.75);
        line-height: calc(50px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoBlogItem_block .RaoBlogItem_block_title {
        font-size: calc(32px * 0.875);
        line-height: calc(50px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoBlogItem_block .RaoBlogItem_block_title {
        font-size: 32px;
        line-height: 50px; } }

.Custom_LinkWithIcon {
  display: flex !important;
  align-items: center; }
  .Custom_LinkWithIcon > img {
    margin-right: 10px; }

/**
 * ----------------------------------------------------------------------------
 * Sign up modal styles
 * ----------------------------------------------------------------------------
*/
body.signup_popup_opened {
  overflow: hidden; }

.ui-widget-content {
  border: none !important;
  background: none !important;
  z-index: -1; }

.ui-dialog {
  background: var(--button-background) !important; }
  .ui-dialog .ui-dialog-titlebar {
    border: none !important;
    background: none !important; }
  .ui-dialog .ui-dialog-title {
    display: none !important; }
  .ui-dialog .ui-dialog-titlebar-close {
    position: absolute !important;
    top: 30px !important;
    right: 30px !important; }

/**
 * ----------------------------------------------------------------------------
 * Side Bar styles
 * ----------------------------------------------------------------------------
*/
#sidebar-menu .SidebarMenu__Nav {
  padding: 0;
  margin: 0; }
  #sidebar-menu .SidebarMenu__Nav .Linklist {
    padding: 0;
    margin: 0; }
    #sidebar-menu .SidebarMenu__Nav .Linklist .Linklist__Item .Link {
      font-weight: 400;
      font-size: 15px;
      line-height: 50px;
      text-transform: uppercase;
      padding: 10px 0; }
#sidebar-menu .Collapsible__Button {
  font-weight: 500;
  font-size: 15px;
  line-height: 50px;
  text-transform: uppercase;
  padding: 10px 0; }
#sidebar-menu .Collapsible__Inner .Collapsible__Content {
  padding: 0;
  padding-bottom: 16px; }
  #sidebar-menu .Collapsible__Inner .Collapsible__Content .Collapsible:not(:last-child) .Collapsible__Button {
    margin-bottom: 10px; }
  #sidebar-menu .Collapsible__Inner .Collapsible__Content .Collapsible__Button {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    opacity: 1;
    color: var(--navigation-text-color);
    padding: 0; }
    #sidebar-menu .Collapsible__Inner .Collapsible__Content .Collapsible__Button img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      object-pisition: center; }

/**
 * ----------------------------------------------------------------------------
 * RaoMayLike block
 * ----------------------------------------------------------------------------
*/
.RaoMayLike .RaoMayLike_wrapper {
  padding: 13% 0; }
  .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper {
    padding-top: 2%;
    padding-bottom: 11%; }
    .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
      font-weight: 400;
      font-size: 24px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.451557px; }
  .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_blocksWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto; }
    .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_blocksWrapper > div:not(:last-child) {
      margin-bottom: 52px; }
  .RaoMayLike .RaoMayLike_wrapper .RaoMayLike__Button_Wraper {
    display: flex;
    justify-content: center;
    padding-top: 11%; }
    .RaoMayLike .RaoMayLike_wrapper .RaoMayLike__Button_Wraper > a {
      max-width: 217px; }

@media screen and (min-width: 641px) {
  .RaoMayLike .RaoMayLike_wrapper {
    padding: 6% 0; }
    .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper {
      padding: 4% 0; }
      .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
        font-weight: 400;
        text-align: center;
        letter-spacing: 1px; }
    .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_blocksWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 6.3%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
      .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_blocksWrapper > div:not(:last-child) {
        margin-bottom: 0; }
    .RaoMayLike .RaoMayLike_wrapper .RaoMayLike__Button_Wraper {
      display: flex;
      justify-content: center;
      padding-top: 4.5%; }
      .RaoMayLike .RaoMayLike_wrapper .RaoMayLike__Button_Wraper > a {
        max-width: 298px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .RaoMayLike .RaoMayLike_wrapper .RaoMayLike_titleWrapper .RaoMayLike_titleContent {
          font-size: 48px;
          line-height: 50px; } }
/**
 * ----------------------------------------------------------------------------
 * Rao big product item styles
 * ----------------------------------------------------------------------------
*/
.RaoSmallProductItem {
  width: 100%; }
  .RaoSmallProductItem .RaoSmallProductItem__Wrapper {
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 8px 16px rgba(112, 109, 145, 0.1);
    height: 100%; }
    .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__image {
      width: 100%; }
      .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__image .ProductItem__Image {
        object-fit: cover;
        object-position: center; }
    .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock {
      width: 100%;
      padding: 6.8%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
      .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title {
        font-weight: 600;
        font-size: 27px;
        line-height: 31px;
        max-width: 100%;
        text-align: start;
        letter-spacing: 1px;
        color: var(--text-color);
        margin-bottom: 5%; }
        @media screen and (min-width: 641px) {
          .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title {
            font-size: calc(32px * 0.62);
            line-height: calc(37px * 0.62); } }
        @media screen and (min-width: 1140px) {
          .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title {
            font-size: calc(32px * 0.62);
            line-height: calc(37px * 0.62); } }
        @media screen and (min-width: 1440px) {
          .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title {
            font-size: calc(32px * 0.75);
            line-height: calc(37px * 0.75); } }
        @media screen and (min-width: 1680px) {
          .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title {
            font-size: calc(32px * 0.875);
            line-height: calc(37px * 0.875); } }
        @media screen and (min-width: 1920px) {
          .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title {
            font-size: 32px;
            line-height: 37px; } }
        .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title .RaoSmallProductItem__textBlock_title_Sub {
          font-size: 20px;
          line-height: 23px;
          font-weight: 300;
          letter-spacing: 1px;
          color: var(--text-color); }
          @media screen and (min-width: 641px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title .RaoSmallProductItem__textBlock_title_Sub {
              font-size: calc(24px * 0.62);
              line-height: calc(28px * 0.62); } }
          @media screen and (min-width: 1140px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title .RaoSmallProductItem__textBlock_title_Sub {
              font-size: calc(24px * 0.62);
              line-height: calc(28px * 0.62); } }
          @media screen and (min-width: 1440px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title .RaoSmallProductItem__textBlock_title_Sub {
              font-size: calc(24px * 0.75);
              line-height: calc(28px * 0.75); } }
          @media screen and (min-width: 1680px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title .RaoSmallProductItem__textBlock_title_Sub {
              font-size: calc(24px * 0.875);
              line-height: calc(28px * 0.875); } }
          @media screen and (min-width: 1920px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_title .RaoSmallProductItem__textBlock_title_Sub {
              font-size: 24px;
              line-height: 28px; } }
      .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper {
        display: flex; }
        .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price {
          font-weight: 600;
          color: #007F7B;
          font-size: 27px;
          line-height: 28px;
          letter-spacing: 1px; }
          @media screen and (min-width: 641px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price {
              font-size: calc(32px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 1140px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price {
              font-size: calc(32px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 1440px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price {
              font-size: calc(32px * 0.75);
              line-height: calc(34px * 0.75); } }
          @media screen and (min-width: 1680px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price {
              font-size: calc(32px * 0.875);
              line-height: calc(34px * 0.875); } }
          @media screen and (min-width: 1920px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price {
              font-size: 32px;
              line-height: 34px; } }
        .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price--compareAt {
          font-weight: 200;
          font-size: 15px;
          line-height: 28px;
          letter-spacing: 0.75px;
          text-decoration-line: line-through;
          margin-left: 5px; }
          @media screen and (min-width: 641px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price--compareAt {
              font-size: calc(18px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 1140px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price--compareAt {
              font-size: calc(18px * 0.62);
              line-height: calc(34px * 0.62); } }
          @media screen and (min-width: 1440px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price--compareAt {
              font-size: calc(18px * 0.75);
              line-height: calc(34px * 0.75); } }
          @media screen and (min-width: 1680px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price--compareAt {
              font-size: calc(18px * 0.875);
              line-height: calc(34px * 0.875); } }
          @media screen and (min-width: 1920px) {
            .RaoSmallProductItem .RaoSmallProductItem__Wrapper .RaoSmallProductItem__textBlock .RaoSmallProductItem__textBlock_priceWrapper .RaoSmallProductItem__textBlock_price--compareAt {
              font-size: 18px;
              line-height: 34px; } }
  .RaoSmallProductItem .RaoSmallProductItem__noReviews {
    margin-bottom: 30px;
    font-weight: 200; }
    @media screen and (min-width: 641px) {
      .RaoSmallProductItem .RaoSmallProductItem__noReviews {
        font-size: calc(24px * 0.62);
        line-height: calc(28px * 0.62); } }
    @media screen and (min-width: 1140px) {
      .RaoSmallProductItem .RaoSmallProductItem__noReviews {
        font-size: calc(24px * 0.62);
        line-height: calc(28px * 0.62); } }
    @media screen and (min-width: 1440px) {
      .RaoSmallProductItem .RaoSmallProductItem__noReviews {
        font-size: calc(24px * 0.75);
        line-height: calc(28px * 0.75); } }
    @media screen and (min-width: 1680px) {
      .RaoSmallProductItem .RaoSmallProductItem__noReviews {
        font-size: calc(24px * 0.875);
        line-height: calc(28px * 0.875); } }
    @media screen and (min-width: 1920px) {
      .RaoSmallProductItem .RaoSmallProductItem__noReviews {
        font-size: 24px;
        line-height: 28px; } }
  .RaoSmallProductItem .RaoSmallProductItem__Reviews {
    margin-bottom: 5%; }
    .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__stars .jdgm-star {
      font-size: 20px;
      color: #FED30E; }
    .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__text {
      margin-left: 10px;
      font-size: 15px;
      line-height: 28px;
      font-family: 'Campton';
      font-style: normal;
      font-weight: 200;
      letter-spacing: 0.75px; }
      @media screen and (min-width: 641px) {
        .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__text {
          font-size: calc(18px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 1140px) {
        .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__text {
          font-size: calc(18px * 0.62);
          line-height: calc(34px * 0.62); } }
      @media screen and (min-width: 1440px) {
        .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__text {
          font-size: calc(18px * 0.75);
          line-height: calc(34px * 0.75); } }
      @media screen and (min-width: 1680px) {
        .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__text {
          font-size: calc(18px * 0.875);
          line-height: calc(34px * 0.875); } }
      @media screen and (min-width: 1920px) {
        .RaoSmallProductItem .RaoSmallProductItem__Reviews .jdgm-prev-badge__text {
          font-size: 18px;
          line-height: 34px; } }
  .RaoSmallProductItem .SingleProductReview_link {
    margin-bottom: -10%; }

/**
 * ----------------------------------------------------------------------------
 * Product recommendation block
 * ----------------------------------------------------------------------------
*/
.ProductRecommendationBlock .ProductRecommendationBlock_wrapper {
  padding: 13% 0; }
  .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper {
    padding-top: 2%;
    padding-bottom: 11%; }
    .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
      font-weight: 300;
      font-size: 24px;
      line-height: 23px;
      text-align: center;
      letter-spacing: 0.451557px; }
  .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_blocksWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto; }
    .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_blocksWrapper > div:not(:last-child) {
      margin-bottom: 52px; }
  .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock__Button_Wraper {
    display: flex;
    justify-content: center;
    padding-top: 11%; }
    .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock__Button_Wraper > a {
      max-width: 217px; }

@media screen and (min-width: 641px) {
  .ProductRecommendationBlock .ProductRecommendationBlock_wrapper {
    padding: 6% 0; }
    .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper {
      padding: 4% 0; }
      .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
        font-weight: 300;
        text-align: center;
        letter-spacing: 1px; }
    .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_blocksWrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 6.3%;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto; }
      .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_blocksWrapper > div:not(:last-child) {
        margin-bottom: 0; }
    .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock__Button_Wraper {
      display: flex;
      justify-content: center;
      padding-top: 4.5%; }
      .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock__Button_Wraper > a {
        max-width: 298px; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .ProductRecommendationBlock .ProductRecommendationBlock_wrapper .ProductRecommendationBlock_titleWrapper .ProductRecommendationBlock_titleContent {
          font-size: 48px;
          line-height: 50px; } }
/**
 * ----------------------------------------------------------------------------
 * Product recommendation block
 * ----------------------------------------------------------------------------
*/
.Custom_productFAQ_Section {
  padding: 15% 5%;
  background: rgba(247, 209, 184, 0.18); }
  .Custom_productFAQ_Section .Faq {
    max-width: 1024px;
    margin-top: 0; }
    .Custom_productFAQ_Section .Faq .Faq_title {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.48px;
      margin-bottom: 5%; }
    .Custom_productFAQ_Section .Faq .Faq__Section {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.48px;
      padding-bottom: 10%; }
    .Custom_productFAQ_Section .Faq .Faq__Item {
      border-bottom: 1px solid var(--text-color); }
      .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper {
        padding: 20px 0; }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question {
          display: flex;
          align-items: center;
          text-align: left; }
          .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question .Faq__Question_text {
            padding-left: 0;
            padding-right: 40px;
            width: 100%;
            position: relative;
            font-size: 18px;
            line-height: 23px; }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__AnswerWrapper .Faq__Answer {
          font-size: 14px; }
      .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Logo_Icon {
        display: flex;
        width: 60px; }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Logo_Icon > svg {
          height: 30px;
          width: auto; }
      .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Icon {
        display: block;
        left: auto;
        right: 0;
        height: 100%; }
      .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Icon::after {
        content: '+';
        font-weight: 400;
        font-size: 54px;
        line-height: 43px;
        letter-spacing: 1px;
        height: 100%;
        display: flex;
        align-items: center; }
    .Custom_productFAQ_Section .Faq .Faq__Item[aria-expanded=true] .Faq__Icon {
      transform: none; }
    .Custom_productFAQ_Section .Faq .Faq__Item[aria-expanded=true] .Faq__Icon::after {
      content: '-'; }

@media screen and (min-width: 641px) {
  .Custom_productFAQ_Section {
    padding: 150px 0;
    background: rgba(247, 209, 184, 0.18); }
    .Custom_productFAQ_Section .Faq {
      max-width: 1024px;
      margin: 35px auto; }
      .Custom_productFAQ_Section .Faq .Faq_title {
        font-weight: 500;
        text-align: center;
        letter-spacing: 1px;
        margin-bottom: 5%; }
      .Custom_productFAQ_Section .Faq .Faq__Section {
        font-weight: 600;
        font-size: 48px;
        line-height: 50px;
        text-align: center;
        letter-spacing: 1px;
        padding-bottom: 0; }
      .Custom_productFAQ_Section .Faq .Faq__Item {
        border-bottom: 1px solid var(--text-color); }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper {
          padding: 20px 0; }
          .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question {
            display: flex;
            align-items: center;
            text-align: left; }
            .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__Question .Faq__Question_text {
              padding-left: 40px;
              padding-right: 0;
              width: 100%;
              position: relative;
              font-size: 24px;
              line-height: 43px;
              letter-spacing: 1px; }
          .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__AnswerWrapper {
            padding-left: 112px;
            padding-right: 35px; }
            .Custom_productFAQ_Section .Faq .Faq__Item .Faq__ItemWrapper .Faq__AnswerWrapper .Faq__Answer {
              font-size: 18px; }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Logo_Icon {
          display: block;
          position: relative;
          widht: auto; }
          .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Logo_Icon > svg {
            height: 50px;
            widht: auto; }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Icon {
          display: block;
          left: auto;
          right: 0; }
        .Custom_productFAQ_Section .Faq .Faq__Item .Faq__Icon::after {
          content: '+';
          font-weight: 600;
          font-size: 54px;
          line-height: 43px;
          letter-spacing: 1px; }
      .Custom_productFAQ_Section .Faq .Faq__Item[aria-expanded=true] .Faq__Icon {
        transform: none; }
      .Custom_productFAQ_Section .Faq .Faq__Item[aria-expanded=true] .Faq__Icon::after {
        content: '-'; } }
      @media screen and (min-width: 641px) and (min-width: 641px) {
        .Custom_productFAQ_Section .Faq .Faq_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1140px) {
        .Custom_productFAQ_Section .Faq .Faq_title {
          font-size: calc(48px * 0.62);
          line-height: calc(50px * 0.62); } }
      @media screen and (min-width: 641px) and (min-width: 1440px) {
        .Custom_productFAQ_Section .Faq .Faq_title {
          font-size: calc(48px * 0.75);
          line-height: calc(50px * 0.75); } }
      @media screen and (min-width: 641px) and (min-width: 1680px) {
        .Custom_productFAQ_Section .Faq .Faq_title {
          font-size: calc(48px * 0.875);
          line-height: calc(50px * 0.875); } }
      @media screen and (min-width: 641px) and (min-width: 1920px) {
        .Custom_productFAQ_Section .Faq .Faq_title {
          font-size: 48px;
          line-height: 50px; } }
/**
 * ----------------------------------------------------------------------------
 * Authors Page Section
 * ----------------------------------------------------------------------------
*/
#shopify-section-page-authors .Container {
  padding: 0 10%; }
  #shopify-section-page-authors .Container .PageHeader {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 5.5%; }
    @media screen and (max-width: 640px) {
      #shopify-section-page-authors .Container .PageHeader {
        flex-direction: column; }
        #shopify-section-page-authors .Container .PageHeader .PageHeader_ImageWrapper {
          width: 100% !important; }
        #shopify-section-page-authors .Container .PageHeader .PageHeader_Info {
          width: 100% !important; }
          #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper {
            padding: 40px 0 0 0 !important; }
            #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
              font-size: 30px;
              line-height: 32px; } }
    #shopify-section-page-authors .Container .PageHeader .PageHeader_ImageWrapper {
      width: 46%;
      margin: 0; }
      #shopify-section-page-authors .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image {
        width: 100%; }
        #shopify-section-page-authors .Container .PageHeader .PageHeader_ImageWrapper .PageHeader_Image img {
          object-fit: cover;
          object-position: center; }
    #shopify-section-page-authors .Container .PageHeader .PageHeader_Info {
      width: 54%; }
      #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper {
        text-align: left;
        padding-top: 15%;
        padding-bottom: 10%; }
        @media screen and (min-width: 641px) {
          #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 1140px) {
          #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 1440px) {
          #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.75);
            line-height: calc(50px * 0.75); } }
        @media screen and (min-width: 1680px) {
          #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: calc(48px * 0.875);
            line-height: calc(50px * 0.875); } }
        @media screen and (min-width: 1920px) {
          #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__Heading {
            font-size: 48px;
            line-height: 50px; } }
        #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
          max-width: 100%;
          font-weight: 200;
          letter-spacing: 1px; }
          @media screen and (min-width: 641px) {
            #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.62);
              line-height: calc(43px * 0.62); } }
          @media screen and (min-width: 1140px) {
            #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.62);
              line-height: calc(43px * 0.62); } }
          @media screen and (min-width: 1440px) {
            #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.75);
              line-height: calc(43px * 0.75); } }
          @media screen and (min-width: 1680px) {
            #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: calc(32px * 0.875);
              line-height: calc(43px * 0.875); } }
          @media screen and (min-width: 1920px) {
            #shopify-section-page-authors .Container .PageHeader .PageHeader_Info .PageHeader_Wraper .SectionHeader__SubHeading {
              font-size: 32px;
              line-height: 43px; } }
  #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container {
    width: 100%;
    display: grid;
    grid-template-columns: 360px auto;
    grid-template-rows: auto;
    grid-template-areas: 'item1 item2';
    grid-gap: 40px;
    padding: 70px;
    border-radius: 32px;
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(98, 97, 117, 0.29);
    margin-bottom: 40px; }
    @media screen and (max-width: 640px) {
      #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container {
        display: flex;
        flex-direction: column;
        padding: 40px; }
        #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Avatar_Container {
          width: 100% !important; }
        #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container {
          align-items: center; }
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
            font-size: 26px; } }
    #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container.no-avatar {
      display: flex;
      justify-content: flex-start; }
    #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Avatar_Container {
      width: 360px;
      grid-area: item1; }
      #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Avatar_Container img {
        width: 100%;
        border-radius: 32px;
        margin: 0; }
    @media screen and (min-width: 641px) and (max-width: 1139px) {
      #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container {
        grid-template-columns: 3fr 4fr;
        padding: 40px; }
        #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Avatar_Container {
          width: 100%;
          min-width: 120px; }
        #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container:nth-child(2n) {
          grid-template-columns: 4fr 3fr !important; } }
    #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container {
      width: 100%;
      grid-area: item2;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column; }
      #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 20px; }
        @media screen and (min-width: 641px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 1140px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
            font-size: calc(48px * 0.62);
            line-height: calc(50px * 0.62); } }
        @media screen and (min-width: 1440px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
            font-size: calc(48px * 0.75);
            line-height: calc(50px * 0.75); } }
        @media screen and (min-width: 1680px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
            font-size: calc(48px * 0.875);
            line-height: calc(50px * 0.875); } }
        @media screen and (min-width: 1920px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_Name {
            font-size: 48px;
            line-height: 50px; } }
      #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_BIO {
        font-weight: 200;
        letter-spacing: .75px; }
        @media screen and (min-width: 641px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_BIO {
            font-size: calc(24px * 0.62);
            line-height: calc(38px * 0.62); } }
        @media screen and (min-width: 1140px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_BIO {
            font-size: calc(24px * 0.62);
            line-height: calc(38px * 0.62); } }
        @media screen and (min-width: 1440px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_BIO {
            font-size: calc(24px * 0.75);
            line-height: calc(38px * 0.75); } }
        @media screen and (min-width: 1680px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_BIO {
            font-size: calc(24px * 0.875);
            line-height: calc(38px * 0.875); } }
        @media screen and (min-width: 1920px) {
          #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container .AuthorPage__Author_Text_Container .AuthorPage__Author_BIO {
            font-size: 24px;
            line-height: 38px; } }
    #shopify-section-page-authors .Container .AuthorPage__Author_Main_Container:nth-child(2n) {
      grid-template-columns: auto 360px;
      grid-template-rows: auto;
      grid-template-areas: 'item2 item1'; }

/**
 * ----------------------------------------------------------------------------
 * Pagination fix for sticky nav
 * ----------------------------------------------------------------------------
*/
.Pagination_Clear {
  clear: both; }
